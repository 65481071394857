import { initializeApp } from 'firebase/app';
import {
  initializeAuth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth';

import {
  getFirebaseApiKey,
  getFirebaseMessagingSenderId,
  getGcpProjectId,
  getFirebaseAppId,
  getNodeEnv,
} from './environments';

const gcpConfig = {
  projectId: getGcpProjectId(),
};

const firebaseConfig = {
  apiKey: getFirebaseApiKey(),
  authDomain:
    getNodeEnv() === 'development'
      ? gcpConfig.projectId + '.firebaseapp.com'
      : window.location.hostname,
  databaseURL: 'https://' + gcpConfig.projectId + '.firebaseio.com',
  projectId: gcpConfig.projectId,
  storageBucket: gcpConfig.projectId + '.appspot.com',
  messagingSenderId: getFirebaseMessagingSenderId(),
  appId: getFirebaseAppId(),
};

export function initFirebase() {
  const app = initializeApp(firebaseConfig);
  return initializeAuth(app, {
    persistence: browserLocalPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
  });
}
