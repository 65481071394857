import {
  RecordedGreetingApprovalStatus,
  FacilityTypeEnum,
  TaskType,
} from '@infinitus/generated/frontend-common';
import { infinitusai, infinitusapi } from '@infinitus/proto/pbjs';

export function getTaskTypeDisplayName(taskType: TaskType | infinitusai.be.TaskType) {
  switch (taskType) {
    case TaskType.TASK_TYPE_MED_SUPP_BV:
    case infinitusai.be.TaskType.TASK_TYPE_MED_SUPP_BV:
      return 'Medicare Supplement Benefits Verification';
    case TaskType.TASK_TYPE_FULL_BI:
    case infinitusai.be.TaskType.TASK_TYPE_FULL_BI:
      return 'Full Benefits Verification';
    case TaskType.TASK_TYPE_PBM_BV:
    case infinitusai.be.TaskType.TASK_TYPE_PBM_BV:
      return 'Pharmacy Benefits Verification';
    case TaskType.TASK_TYPE_COVID_VACCINATIONS:
    case infinitusai.be.TaskType.TASK_TYPE_COVID_VACCINATIONS:
      return 'Covid Vaccination Availability';
    case TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER:
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER:
      return 'Prescription Transfer';
    case TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS:
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS:
      return 'Prescription Savings';
    case TaskType.TASK_TYPE_CLAIMS_FOLLOWUP:
    case infinitusai.be.TaskType.TASK_TYPE_CLAIMS_FOLLOWUP:
      return 'Claims Follow Up';
    case TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
    case infinitusai.be.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
      return 'Prior Auth Follow Up';
    case TaskType.TASK_TYPE_DENTAL_BV:
    case infinitusai.be.TaskType.TASK_TYPE_DENTAL_BV:
      return 'Dental Benefits Verification';
    case TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP:
    case infinitusai.be.TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP:
      return 'Shipment Follow Up';
    case TaskType.TASK_TYPE_PATIENT_OUTREACH:
    case infinitusai.be.TaskType.TASK_TYPE_PATIENT_OUTREACH:
      return 'Patient Outreach';
    case TaskType.TASK_TYPE_PROVIDER_OUTREACH:
    case infinitusai.be.TaskType.TASK_TYPE_PROVIDER_OUTREACH:
      return 'Provider Outreach';
    case TaskType.TASK_TYPE_FASTTRACK_MM:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_MM:
      return 'FastTrack Major Medical BV';
    case TaskType.TASK_TYPE_FASTTRACK_PBM:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_PBM:
      return 'FastTrack PBM BV';
    case TaskType.TASK_TYPE_PHARMACY_STOCK_CHECK:
    case infinitusai.be.TaskType.TASK_TYPE_PHARMACY_STOCK_CHECK:
      return 'Pharmacy Stock Check';
    case TaskType.TASK_TYPE_BASIC_BV:
    case infinitusai.be.TaskType.TASK_TYPE_BASIC_BV:
      return 'Basic Benefits Verification';
    case TaskType.TASK_TYPE_FASTTRACK_CLAIMS_APPEAL:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_APPEAL:
      return 'FastTrack Claims Appeal';
    case TaskType.TASK_TYPE_FASTTRACK_CLAIMS_DENIAL:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_DENIAL:
      return 'FastTrack Claims Denial';
    case TaskType.TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP:
      return 'FastTrack Claims Follow Up';
    case TaskType.TASK_TYPE_PBM_DISCOVERY:
    case infinitusai.be.TaskType.TASK_TYPE_PBM_DISCOVERY:
      return 'Pharmacy Benefit Manager Discovery';
    case TaskType.TASK_TYPE_HEALTH_RISK_ASSESSMENT:
    case infinitusai.be.TaskType.TASK_TYPE_HEALTH_RISK_ASSESSMENT:
      return 'Health Risk Assessment';
    case TaskType.TASK_TYPE_FASTTRACK_PRIOR_AUTH:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_PRIOR_AUTH:
      return 'FastTrack Prior Auth';
    case infinitusai.be.TaskType.TASK_TYPE_INBOUND:
      return 'Inbound';
    case TaskType.TASK_TYPE_MM_DISCOVERY:
    case infinitusai.be.TaskType.TASK_TYPE_MM_DISCOVERY:
      return 'Major Medical Discovery';
    case TaskType.TASK_TYPE_UNKNOWN:
    case infinitusai.be.TaskType.TASK_TYPE_UNKNOWN:
      return 'Unknown';
  }
}

export const getTaskTypeDisplayNamePortal = (
  taskType: infinitusai.be.TaskType | keyof typeof infinitusai.be.TaskType
): string => {
  if (!taskType) return 'Unknown';
  const enumValue = typeof taskType === 'string' ? infinitusai.be.TaskType[taskType] : taskType;
  switch (enumValue) {
    case infinitusai.be.TaskType.TASK_TYPE_FULL_BI:
      return 'Major Medical';
    case infinitusai.be.TaskType.TASK_TYPE_PBM_BV:
      return 'PBM';
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER:
      return 'Prescription Transfer';
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS:
      return 'Prescription Savings';
    case infinitusai.be.TaskType.TASK_TYPE_CLAIMS_FOLLOWUP:
      return 'Claims Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
      return 'Prior Auth Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_DENTAL_BV:
      return 'Dental BV';
    case infinitusai.be.TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP:
      return 'Shipment Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_PATIENT_OUTREACH:
      return 'Patient Outreach';
    case infinitusai.be.TaskType.TASK_TYPE_PROVIDER_OUTREACH:
      return 'Provider Outreach';
    case infinitusai.be.TaskType.TASK_TYPE_PHARMACY_STOCK_CHECK:
      return 'Pharmacy Stock Check';
    case infinitusai.be.TaskType.TASK_TYPE_BASIC_BV:
      return 'Basic Benefits Verification';
    case infinitusai.be.TaskType.TASK_TYPE_PBM_DISCOVERY:
      return 'PBM Discovery';
    case infinitusai.be.TaskType.TASK_TYPE_MED_SUPP_BV:
      return 'Med Supp BV';
    case infinitusai.be.TaskType.TASK_TYPE_COVID_VACCINATIONS:
      return 'Covid Vaccination';
    case infinitusai.be.TaskType.TASK_TYPE_INBOUND:
      return 'Inbound';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_MM:
      return 'Fasttrack Major Medical';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_PBM:
      return 'Fasttrack PBM';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP:
      return 'Fasttrack Claims Followup';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_DENIAL:
      return 'Fasttrack Claims Denial';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_APPEAL:
      return 'Fasttrack Claims Appeal';
    case infinitusai.be.TaskType.TASK_TYPE_HEALTH_RISK_ASSESSMENT:
      return 'HRA';
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_PRIOR_AUTH:
      return 'Fasttrack Prior Auth';
    case infinitusai.be.TaskType.TASK_TYPE_MM_DISCOVERY:
      return 'Major Medical Discovery';
    case infinitusai.be.TaskType.TASK_TYPE_UNKNOWN:
      return 'Unknown';
  }
};

export const getFacilityTypeDisplayName = (
  facilityType: FacilityTypeEnum | infinitusai.be.FacilityType.Type | infinitusapi.INFFacilityType
): string => {
  if (!facilityType) return 'Unknown';
  switch (facilityType) {
    case infinitusai.be.FacilityType.Type.HOSPITAL_OUTPATIENT:
    case FacilityTypeEnum.HOSPITAL_OUTPATIENT:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT:
      return 'Hospital Outpatient';
    case infinitusai.be.FacilityType.Type.PHARMACY:
    case FacilityTypeEnum.PHARMACY:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_PHARMACY:
      return 'Pharmacy';
    case infinitusai.be.FacilityType.Type.SPECIALIST_OFFICE:
    case FacilityTypeEnum.SPECIALIST_OFFICE:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_SPECIALIST_OFFICE:
      return 'Specialist Office';
    case infinitusai.be.FacilityType.Type.SHIP_TO_HOME:
    case FacilityTypeEnum.SHIP_TO_HOME:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_SHIP_TO_HOME:
      return 'Ship to Home';
    case infinitusai.be.FacilityType.Type.INFUSION_CENTER:
    case FacilityTypeEnum.INFUSION_CENTER:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_INFUSION_CENTER:
      return 'Infusion Center';
    case infinitusai.be.FacilityType.Type.AMBULATORY_SURGICAL_CENTER:
    case FacilityTypeEnum.AMBULATORY_SURGICAL_CENTER:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_AMBULATORY_SURGICAL_CENTER:
      return 'Ambulatory Surgical Center';
    case infinitusai.be.FacilityType.Type.HOME_INFUSION:
    case FacilityTypeEnum.HOME_INFUSION:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_HOME_INFUSION:
      return 'Home Infusion';
    case infinitusai.be.FacilityType.Type.FED_QUALIFIED_HC:
    case FacilityTypeEnum.FED_QUALIFIED_HC:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_FED_QUALIFIED_HC:
      return 'Federally Qualified Health Center';
    case infinitusai.be.FacilityType.Type.HOSPICE:
    case FacilityTypeEnum.HOSPICE:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_HOSPICE:
      return 'Hospice';
    case infinitusai.be.FacilityType.Type.HOSPITAL_INPATIENT:
    case FacilityTypeEnum.HOSPITAL_INPATIENT:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_HOSPITAL_INPATIENT:
      return 'Hospital Inpatient';
    case infinitusai.be.FacilityType.Type.HOSPITAL_OUTPATIENT_OFF_CAMPUS:
    case FacilityTypeEnum.HOSPITAL_OUTPATIENT_OFF_CAMPUS:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_HOSPITAL_OUTPATIENT_OFF_CAMPUS:
      return 'Hospital Off-Campus Outpatient';
    case infinitusai.be.FacilityType.Type.IHC:
    case FacilityTypeEnum.IHC:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_IHC:
      return 'IHC';
    case infinitusai.be.FacilityType.Type.INPATIENT_REHAB:
    case FacilityTypeEnum.INPATIENT_REHAB:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_INPATIENT_REHAB:
      return 'Inpatient Rehab';
    case infinitusai.be.FacilityType.Type.OUTPATIENT_REHAB:
    case FacilityTypeEnum.OUTPATIENT_REHAB:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_OUTPATIENT_REHAB:
      return 'Outpatient Rehab';
    case infinitusai.be.FacilityType.Type.PUBLIC_HEALTH_CLINIC:
    case FacilityTypeEnum.PUBLIC_HEALTH_CLINIC:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_PUBLIC_HEALTH_CLINIC:
      return 'Public Health Clinic';
    case infinitusai.be.FacilityType.Type.RURAL_HEALTH_CLINIC:
    case FacilityTypeEnum.RURAL_HEALTH_CLINIC:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_RURAL_HEALTH_CLINIC:
      return 'Rural Health Clinic';
    case infinitusai.be.FacilityType.Type.SNF:
    case FacilityTypeEnum.SNF:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_SNF:
      return 'Skilled Nursing Facility';
    case infinitusai.be.FacilityType.Type.DIAGNOSTIC_TEST_LAB:
    case FacilityTypeEnum.DIAGNOSTIC_TEST_LAB:
    case infinitusapi.INFFacilityType.INF_FACILITY_TYPE_DIAGNOSTIC_TEST_LAB:
      return 'Diagnostic Test Lab';
    default:
      return 'Unknown';
  }
};

export function getRecordedGreetingApprovalStatusDisplayName(type: RecordedGreetingApprovalStatus) {
  switch (type) {
    case RecordedGreetingApprovalStatus.GREETING_APPROVED:
      return 'Approved';
    case RecordedGreetingApprovalStatus.GREETING_PENDING:
      return 'Pending Approval';
    case RecordedGreetingApprovalStatus.GREETING_REJECTED:
      return 'Rejected';
    default:
      return 'Unknown';
  }
}
