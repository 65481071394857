import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { useNexmoClient } from '@infinitus/nexmo';
import { useVoice } from '@infinitus/voice';

import { IVoipContext, VoipClient, VoipContext } from './VoipContext';
import useLiveKitClient from './livekit/useLiveKitClient';
import { useSyncLivekitAudioDeviceSettings } from './useSyncLivekitAudioDeviceSettings';

interface Props {
  children: ReactNode;
}

export default function VoipProvider({ children }: Props) {
  const nc = useNexmoClient();
  const ncRef = useRef(nc);
  ncRef.current = nc;

  const lc = useLiveKitClient();
  const lcRef = useRef(lc);
  lcRef.current = lc;

  const voiceOptions = useVoice();
  const voiceOptionsRef = useRef(voiceOptions);
  voiceOptionsRef.current = voiceOptions;

  const [selectedVoipClient, setSelectedVoipClient] =
    useState<IVoipContext['selectedVoipClient']>(undefined);
  const selectedVoipClientRef = useRef<typeof selectedVoipClient>(selectedVoipClient);
  selectedVoipClientRef.current = selectedVoipClient;

  // Note: We are only syncing the livekit client input/output audio device settings
  // because the NexmoClientProvider already syncs its own devices in a similar
  // manner. Avoided moving the synchronization here since this change was made
  // during the latter half pf blizzard
  useSyncLivekitAudioDeviceSettings({ livekitClient: lc, voiceOptions });

  const join: IVoipContext['join'] = useCallback(
    async ({
      voipClient,
      orgUuid,
      taskUuid,
      callUuid,
      muteMicByDefault,
      nexmoConversationId,
      source,
      force,
    }) => {
      // This guard is in place to help protect against joining a vonage leg and
      // livekit room at the same time. whenever switching voip providers, we should
      // ensure we have left the other voip provider calls.
      if (selectedVoipClientRef.current && selectedVoipClientRef.current !== voipClient) {
        console.log(
          'Ignoring voip join request as we are already joined to a client. leave first.'
        );
        return;
      }
      setSelectedVoipClient(voipClient);
      selectedVoipClientRef.current = voipClient;
      const {
        autoGainControl,
        echoCancellation,
        noiseSuppression,
        microphoneDeviceId,
        speakerDeviceId,
      } = voiceOptionsRef.current;
      switch (voipClient) {
        case VoipClient.LIVEKIT:
          lcRef.current.join({
            orgUuid,
            taskUuid,
            callUuid,
            muteMicByDefault,
            autoGainControl,
            echoCancellation,
            noiseSuppression,
            microphoneDeviceId,
            speakerDeviceId,
          });
          break;
        case VoipClient.VONAGE:
          if (nexmoConversationId) {
            await ncRef.current.attach({
              conversationUuid: nexmoConversationId,
              muteMicByDefault: muteMicByDefault,
              orgUuid,
              source,
              force,
              autoGainControl,
              noiseSuppression,
              echoCancellation,
              deviceId: microphoneDeviceId,
            });
          }
          break;
      }
    },
    []
  );

  const leave: IVoipContext['leave'] = useCallback(async () => {
    if (!selectedVoipClientRef.current) {
      return;
    }
    try {
      switch (selectedVoipClientRef.current) {
        case VoipClient.LIVEKIT:
          lcRef.current.leave();
          break;
        case VoipClient.VONAGE:
          await ncRef.current.maybeLeaveConversation();
          break;
      }
    } catch (e: any) {
      throw e;
    } finally {
      // Optimistically reset the selected voip client even if leaving a call
      // errors. The errors thrown by maybeLeaveConversation are usually related
      // to the conversation already having been ended.
      setSelectedVoipClient(undefined);
      selectedVoipClientRef.current = undefined;
    }
  }, []);

  // Note: there is no concept of starting a new session for livekit
  const startNewSession: IVoipContext['startNewSession'] = useCallback(async () => {
    await ncRef.current.startNewSession();
  }, []);

  // Note: there is no concept of deleting a session for livekit
  const deleteSession: IVoipContext['deleteSession'] = useCallback(async () => {
    await ncRef.current.deleteSession();
  }, []);

  const value: IVoipContext = useMemo(
    () => ({
      selectedVoipClient,
      join,
      leave,
      startNewSession,
      deleteSession,
    }),
    [selectedVoipClient, join, leave, startNewSession, deleteSession]
  );

  return <VoipContext.Provider value={value}>{children}</VoipContext.Provider>;
}
