export enum PerformanceMarks {
  // When acknowledgement is received for an operator heartbeat websocket message
  OPERATOR_HEARTBEAT_SENT_VIA_WEBSOCKET = 'OPERATOR_HEARTBEAT_SENT_VIA_WEBSOCKET',
  // When the message is received from the websocket
  OPERATOR_SHOULD_JOIN_CALL_RECEIVED_VIA_WEBSOCKET = 'OPERATOR_SHOULD_JOIN_CALL_RECEIVED_VIA_WEBSOCKET',
  // When our app starts the history.push to route the operator to the call page
  OPERATOR_ROUTED_BY_JOIN_CALL_MESSAGE = 'OPERATOR_ROUTED_BY_JOIN_CALL_MESSAGE',
  // When the operator starts loading the call page React component
  OPERATOR_STARTED_LOADING_CALL_PAGE = 'OPERATOR_STARTED_LOADING_CALL_PAGE',
  // When the operator makes first attempt to notify the backend of presence via REST
  OPERATOR_INITIATING_RESTFUL_PRESENCE_REQUEST = 'OPERATOR_INITIATING_RESTFUL_PRESENCE_REQUEST',
  // When the operator completes their RESTful presence request
  OPERATOR_COMPLETED_RESTFUL_PRESENCE_REQUEST = 'OPERATOR_COMPLETED_RESTFUL_PRESENCE_REQUEST',
  // When the operator clicks a suggestion to deliver an utterance
  OPERATOR_CLICKED_SUGGESTION = 'OPERATOR_CLICKED_SUGGESTION',
  // When the executeAction call completes
  EXECUTE_ACTION_API_CALL_COMPLETED = 'EXECUTE_ACTION_API_CALL_COMPLETED',
  // When Nexmo starts connecting to the conversation
  NEXMO_AUDIO_STARTED_CONNECTING = 'NEXMO_AUDIO_STARTED_CONNECTING',
  // When Nexmo completes its connection successfully
  NEXMO_AUDIO_COMPLETED_CONNECTING = 'NEXMO_AUDIO_COMPLETED_CONNECTING',
}

export enum PerformanceMeasures {
  TIME_TO_HEARTBEAT_ACKNOWLEDGED = 'TIME_TO_HEARTBEAT_ACKNOWLEDGED',
  TIME_TO_RESPOND_TO_JOIN_CALL_WEBSOCKET_MESSAGE = 'TIME_TO_RESPOND_TO_JOIN_CALL_WEBSOCKET_MESSAGE',
  TIME_TO_START_LOADING_CALL_PAGE_AFTER_ROUTING = 'TIME_TO_START_LOADING_CALL_PAGE_AFTER_ROUTING',
  TIME_TO_INITIATE_FIRST_PRESENCE_REQUEST_AFTER_RECEIVING_JOIN_CALL_MESSAGE = 'TIME_TO_ISSUE_FIRST_PRESENCE_REQUEST_AFTER_RECEIVING_JOIN_CALL_MESSAGE,',
  TIME_TO_COMPLETE_FIRST_PRESENCE_REQUEST_AFTER_RECEIVING_JOIN_CALL_MESSAGE = 'TIME_TO_COMPLETE_FIRST_PRESENCE_REQUEST_AFTER_RECEIVING_JOIN_CALL_MESSAGE',
  TIME_TO_COMPLETE_EXECUTE_ACTION = 'TIME_TO_COMPLETE_EXECUTE_ACTION',
  TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO = 'TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO',
}
