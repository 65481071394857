
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Category": [
      "DeepCategory",
      "FlatCategory"
    ],
    "CategoryDef": [
      "DeepCategoryDef",
      "FlatCategoryDef"
    ],
    "ConversationFunctionParameter": [
      "ConversationFunctionArrayParameter",
      "ConversationFunctionStringParameter"
    ],
    "EbvOutputValue": [
      "BooleanType",
      "EbvDate",
      "EnumType",
      "IntType",
      "MoneyType",
      "StringType"
    ],
    "Effect": [
      "FollowupSuggestionVisibilityEffect",
      "OutputFieldValueEffect",
      "OutputFieldVisibilityEffect"
    ],
    "EqualValue": [
      "BooleanType",
      "DateType",
      "EnumType",
      "EnumsType",
      "IntType",
      "MoneyType",
      "OutputReferenceType",
      "StringType"
    ],
    "InequalityValue": [
      "DateType",
      "IntType",
      "MoneyType",
      "OutputReferenceType",
      "StringType"
    ],
    "InputValue": [
      "ArrayValue",
      "BoolValue",
      "DateValue",
      "FloatValue",
      "IntValue",
      "MoneyValue",
      "ObjectValue",
      "StringValue"
    ],
    "OutputValue": [
      "AgentCouldNotAnswerType",
      "BooleanType",
      "DateType",
      "EnumType",
      "EnumsType",
      "IntType",
      "MoneyType",
      "StringType"
    ],
    "PluralPredicate": [
      "CountGTPredicate"
    ],
    "Predicate": [
      "EqualPredicate",
      "GTEPredicate",
      "GTPredicate",
      "HasPrefixPredicate",
      "HasSubstringPredicate",
      "InPredicate",
      "LTEPredicate",
      "LTPredicate",
      "NotEqualPredicate",
      "NotInPredicate"
    ],
    "QueuesForUserOrError": [
      "FastTrackError",
      "QueuesForUser"
    ],
    "Suggestion": [
      "FollowupSuggestion",
      "PrimarySuggestion",
      "TopSuggestion"
    ],
    "SuggestionDef": [
      "FollowupSuggestionDef",
      "PrimarySuggestionDef"
    ],
    "Type": [
      "Enum",
      "Enums",
      "MaybeAutocompleteString",
      "Scalar"
    ]
  }
};
      export default result;
    