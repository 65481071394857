import { IconNames } from '@infinitus/components/Icon';
import { getPhoneNumbersPageUrl } from 'utils';

import { NavItemList } from './NewAppNav/types';

interface RouteProps {
  canShowTaskList: boolean;
  isAdmin: boolean;
  orgName: string;
  showUnifiedQueue: boolean;
}

const makeOpsRoute = (orgName: string, slug?: string) =>
  `${orgName}/operator${slug ? `/${slug}` : ''}`;

const makeAdminRoute = (orgName: string, slug: string) => `${orgName}/admin/${slug}`;

export function getRoutes({
  isAdmin,
  orgName,
  showUnifiedQueue,
  canShowTaskList,
}: RouteProps): NavItemList {
  var navArr: any[] = [];

  if (showUnifiedQueue) {
    navArr = [
      {
        href: `${orgName}/queue`,
        iconName: IconNames.JOIN_INNER,
        id: 'queue',
        title: 'Unified queue',
      },
    ];
  } else {
    // Show org queue
    navArr = [
      {
        href: makeOpsRoute(orgName, 'ready'),
        id: 'ready',
        iconName: IconNames.RING_VOLUME,
        title: 'Ready page',
      },
    ];
  }

  const operatorRouteTaskChildren = [
    {
      href: makeOpsRoute(
        orgName,
        'task-list/all?state=CALL+CONNECTED&state=CALL+DISCONNECTED&state=READY+FOR+CALL&state=READY+FOR+REVIEW&state=IN+REVIEW&state=READY+FOR+SELF+REVIEW'
      ),
      iconName: IconNames.ASSIGNMENT,
      id: 'all',
      title: 'All tasks',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/pending'),
      id: 'pending',
      iconName: IconNames.PENDING_ACTIONS,
      title: 'Tasks pending',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/in-progress'),
      iconName: IconNames.CONTENT_PASTE_GO,
      id: 'in-progress',
      title: 'Tasks in progress',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/for-review'),
      id: 'for-review',
      iconName: IconNames.CONTENT_PASTE_SEARCH,
      title: 'Tasks for review',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/in-review'),
      iconName: IconNames.INVENTORY,
      iconOutlined: true,
      id: 'in-review',
      title: 'Tasks in review',
    },
  ];

  if (canShowTaskList) {
    if (isAdmin && orgName === 'infinitus_test_pool') {
      navArr.push(...operatorRouteTaskChildren, {
        href: makeOpsRoute(orgName, 'task-list/tagged-tasks'),
        iconName: IconNames.MISCELLANEOUS_SERVICES,
        iconOutlined: true,
        id: 'tagged-tasks',
        title: 'Tagged tasks',
      });
    } else {
      navArr.push(...operatorRouteTaskChildren);
    }
  }

  if (isAdmin) {
    navArr.push(
      {
        href: makeOpsRoute(orgName, 'training'),
        iconName: IconNames.SCHOOL,
        id: 'training',
        title: 'Training',
      },
      {
        id: 'training-scripts',
        iconName: IconNames.LOCAL_LIBRARY,
        href: makeAdminRoute(orgName, 'training-scripts?taskType=TASK_TYPE_FULL_BI'),
        title: 'Training scripts',
      },

      {
        id: 'priorityTasks',
        href: makeAdminRoute(orgName, 'priorityTasks'),
        iconName: IconNames.PLAYLIST_ADD_CHECK,
        title: 'Priority tasks',
      },
      {
        id: 'active-calls',
        href: makeAdminRoute(orgName, 'active-calls'),
        iconName: IconNames.PHONE,
        title: 'Active calls',
      },
      {
        id: 'queues',
        href: makeAdminRoute(orgName, 'queues'),
        iconName: IconNames.EDIT_NOTE,
        title: 'Queue editor',
      },
      {
        id: 'unifiedQueueManager',
        href: makeAdminRoute(orgName, 'unifiedQueueManager'),
        iconName: IconNames.VIEW_LIST,
        tooltip: 'Unified queue management',
        title: 'UQ management',
      },
      {
        id: 'skillDefinitions',
        href: makeAdminRoute(orgName, 'skillDefinitions'),
        iconName: IconNames.PSYCHOLOGY,
        title: 'Skill editor',
      },
      {
        id: 'userSkills',
        href: makeAdminRoute(orgName, 'userSkills'),
        iconName: IconNames.PERSON_SEARCH,
        title: 'User skills',
      },

      {
        id: 'customers',
        href: makeAdminRoute(orgName, 'customers'),
        iconName: IconNames.WORK,
        title: 'Customers',
      },
      {
        id: 'orgs',
        href: makeAdminRoute(orgName, 'orgs'),
        iconName: IconNames.PEOPLE,
        title: 'Orgs',
      },

      {
        id: 'payers',
        href: makeAdminRoute(orgName, 'payers'),
        iconName: IconNames.SAVINGS,
        title: 'Payors',
      },
      {
        id: 'phoneNumbers',
        href: getPhoneNumbersPageUrl(orgName),
        iconName: IconNames.DIALPAD,
        title: 'Phone numbers',
      },

      {
        id: 'convo',
        iconName: IconNames.FORUM,
        href: makeAdminRoute(orgName, orgName ? `convo/${orgName}` : `convo`),
        title: 'Convo builder',
      },

      {
        title: 'CB2 outputs',
        id: 'cb2-outputs',
        iconName: IconNames.OUTPUT,
        href: makeAdminRoute(orgName, 'library/cb2-outputs'),
        tooltip: 'Convo builder version 2 standard outputs',
      },
      {
        id: 'cb2-conditions',
        iconName: IconNames.FORK_RIGHT,
        href: makeAdminRoute(orgName, 'library/cb2-conditions'),
        title: 'CB2 conditions',
        tooltip: 'Convo builder version 2 standard conditions',
      },
      {
        id: 'cb2-actions-utterances',
        iconName: IconNames.ASSISTANT,
        iconOutlined: true,
        href: makeAdminRoute(orgName, 'library/cb2-actions-utterances'),
        title: 'CB2 actions and utterances',
        tooltip: 'Convo builder version 2 standard actions and utterances',
      },
      {
        id: 'cb2-modules',
        iconName: IconNames.ACCOUNT_TREE,
        iconOutlined: true,
        href: makeAdminRoute(orgName, 'library/cb2-modules'),
        title: 'CB2 modules',
        tooltip: 'Convo builder version 2 standard modules',
      },

      {
        id: 'outputs',
        iconName: IconNames.OUTPUT,
        href: makeAdminRoute(orgName, 'library/outputs'),
        title: 'CB1 outputs',
      },
      {
        id: 'autocomplete',
        href: makeAdminRoute(orgName, 'library/autocomplete'),
        iconName: IconNames.RTT,
        title: 'Autocomplete',
        tooltip: 'Autocomplete options',
      },
      {
        id: 'conditions',
        iconName: IconNames.FORK_RIGHT,
        href: makeAdminRoute(
          orgName,
          orgName ? `library/conditions/${orgName}` : 'library/conditions'
        ),
        title: 'CB1 conditions',
      },
      {
        id: 'products',
        href: makeAdminRoute(orgName, 'library/products'),
        iconName: IconNames.LOCAL_PHARMACY,
        title: 'Products',
      },
      {
        id: 'integration-testing',
        href: makeAdminRoute(orgName, 'library/integration-testing'),
        iconName: IconNames.LOCAL_OFFER,
        title: 'Integration tags',
        tooltip: 'self service integration testing tags',
      },
      {
        id: 'cpt-library',
        href: makeAdminRoute(orgName, 'library/cpt'),
        iconName: IconNames.HEALTH_AND_SAFETY,
        title: 'CPT Library',
        tooltip: 'CPT diagnosis code information library',
      },
      {
        id: 'ebv',
        href: makeAdminRoute(orgName, 'library/ebv'),
        iconName: IconNames.API,
        title: 'EBV Sources',
        tooltip: 'Electronic benefit verification sources',
      },
      {
        id: 'api-outputs-config',
        href: makeAdminRoute(orgName, 'library/apiOutputConfigs'),
        iconName: IconNames.IMPORT_EXPORT,
        title: 'API Output Config',
      },

      {
        id: 'incidents',
        href: makeAdminRoute(orgName, 'incidents'),
        iconName: IconNames.REPORT,
        title: 'Incident Reports',
      },
      {
        id: 'incidentConfig',
        href: makeAdminRoute(orgName, 'incidentConfig?sort_dir=asc&sort=category'),
        iconName: IconNames.RULE_FOLDER,
        title: 'Incident types',
      },
      {
        id: 'issues',
        href: makeAdminRoute(orgName, 'issues'),
        iconName: IconNames.FLAG,
        title: 'Issues',
      },

      {
        id: 'notifications',
        href: makeAdminRoute(orgName, 'notifications'),
        iconName: IconNames.EDIT_NOTIFICATIONS,
        title: 'Notification builder',
      },
      {
        id: 'experiments',
        href: makeAdminRoute(orgName, 'experiments'),
        iconName: IconNames.SCIENCE,
        title: 'Experiments',
      }
    );
  } else {
    // Not admin
    navArr.push({
      id: 'training',
      href: makeOpsRoute(orgName, 'training'),
      iconName: IconNames.SCHOOL,
      title: 'Training',
    });
  }

  return navArr;
}
