import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { alpha, useTheme } from '@mui/material/styles';
import numeral from 'numeral';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
  onClick?: () => void;
}

const useClasses = () => {
  const theme = useTheme();
  return {
    root: {
      position: 'fixed',
      minWidth: '40em',
      top: 0,
      right: 10,
      width: 'auto',
      background: alpha(theme.palette.common.black, 0.6),
      padding: `0 ${theme.spacing(1)}px`,
      borderRadius: `0 0 6px 6px`,
      cursor: 'pointer',
      zIndex: 1202,
    },
    metric: {
      fontSize: '0.6rem',
    },
  };
};

// Frequency of updates in ms
const REFRESH_RATE = 1000;
const METRICS = ['totalJSHeapSize', 'usedJSHeapSize'];
const DevelopmentPanel: React.FunctionComponent<Props> = ({ onClick }) => {
  const classes = useClasses();
  const timerRef = useRef(0);

  const [rateOfGrowth, setRateOfGrowth] = useState(0);
  const [memorySnapshot, setMemorySnapshot] = useState(window.performance.memory);
  useEffect(() => {
    timerRef.current = window.setInterval(() => {
      const newSnapshot = window.performance.memory;
      setRateOfGrowth((newSnapshot.usedJSHeapSize - memorySnapshot.usedJSHeapSize) / 1024);
      setMemorySnapshot(newSnapshot);
    }, REFRESH_RATE);
    return () => {
      clearInterval(timerRef.current);
    };
  });
  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      onClick={onClick}
      spacing={1}
      sx={classes.root}
    >
      {METRICS.map((field) => (
        <Grid key={field}>
          <Typography sx={classes.metric} variant="caption">
            {field}: {numeral(memorySnapshot[field]).format('0.000 b')} (
            {numeral(memorySnapshot[field]).format('0,0')})
          </Typography>
        </Grid>
      ))}
      <Grid>
        <Typography sx={classes.metric} variant="caption">
          &Delta;: {numeral(rateOfGrowth).format('0,0')} kB/s
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DevelopmentPanel;
