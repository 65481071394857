import { useApolloClient } from '@apollo/client';
import { UserOrg, useAuth } from '@infinitusai/auth';
import { createFilterOptions } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { matches, partition } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import useCallParams from '@infinitus/hooks/useCallParams';
import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import { Choice } from '@infinitus/types/ui-input-types';
import dayjs from '@infinitus/utils/dayjs';
import { getTaskTypeDisplayName } from '@infinitus/utils/displayNames';
import { GetTaskDocument } from '@infinitus/utils/graphql';
import Autocomplete from 'components/Autocomplete';
import { TextField } from 'components/TextField';
import useCallState from 'hooks/useCallState';
import MuiTheme from 'styles/MuiTheme';
import { UUID } from 'types';
import { getCallPageUrl, getOrgInfo, getTaskPageUrl } from 'utils';
import RecentCallsService, {
  buildRecentCallsQueryFunction,
  getRecentCallEntryId,
  RecentCallEntry,
} from 'utils/RecentCallsService';
import { getUuidDisplayName } from 'utils/displayNames';
import { getOperatorPortalUrl } from 'utils/environments';

export interface RecentCallFilter {
  excludeProductionOrgs?: boolean;
  excludeTaskUuids?: UUID[];
  excludeTestingDisabledOrgs?: boolean;
  orgUuid?: UUID;
  taskState?: NonNullable<RecentCallEntry['task']>['state'];
  taskType?: NonNullable<RecentCallEntry['task']>['taskType'];
  type?: 'call' | 'task';
}

type InfoField = 'taskType' | 'orgName';

interface Props {
  displayField?: InfoField;
  filter?: RecentCallFilter;
  isVisible?: boolean;
  label?: string;
  onChange: (historyEntryId: string) => unknown;
  subtitleField?: InfoField;
  value: string;
}

const TASK_OR_CALL_URL_EXP =
  /\/([^/]+)\/operator\/tasks\/([a-fA-F\d]{8}(?:-[a-fA-F\d]{4}){3}-[a-fA-F\d]{12})(?:\/calls\/([a-fA-F\d]{8}(?:-[a-fA-F\d]{4}){3}-[a-fA-F\d]{12}))?/;

export default function RecentCallPicker({
  displayField = 'taskType',
  filter = {},
  isVisible = true,
  label = 'Related call/task',
  onChange,
  subtitleField = 'orgName',
  value,
}: Props) {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const { orgs, user } = useAuth();

  const callParams = useCallParams();
  const { getCallState } = useCallState();
  const callState = getCallState();
  const currentEntryId = getRecentCallEntryId(callParams);

  const [isLoading, setIsLoading] = useState(false);

  const activeCallId = callState?.id;
  const [isConnectedToCall, setIsConnectedToCall] = useState<boolean>();
  const callHistoryQuery = useMemo(
    () => buildRecentCallsQueryFunction(user?.uid || ''),
    [user?.uid]
  );
  const [callHistory, setCallHistory] = useState<RecentCallEntry[]>([]);
  const [recentCallChoices, setRecentCallChoices] = useState<Choice<string, RecentCallEntry>[]>([]);
  const [manualEntry, setManualEntry] = useState<RecentCallEntry>();
  const [callOrTaskUrl, setCallOrTaskUrl] = useState('');

  const isCallOrTaskUrlInvalid = !isLoading && !!callOrTaskUrl && !value;

  useEffect(() => {
    const getLatestCallHistory = async () => {
      try {
        const results = await callHistoryQuery();
        setCallHistory(results);
      } catch (error) {
        setCallHistory([]);
      }
    };

    if (isVisible) {
      void getLatestCallHistory();
    }
  }, [callHistoryQuery, isVisible]);

  // Build URL for newly selected call/task entry
  useEffect(() => {
    let matchingEntry: RecentCallEntry | undefined;
    let newCallOrTaskUrl = '';

    if (value) {
      if (manualEntry?.id === value) {
        matchingEntry = manualEntry;
      } else {
        matchingEntry = recentCallChoices
          .map(({ data }) => data)
          .find((data) => data?.id === value);
      }
    }

    if (matchingEntry) {
      const { orgName, callUuid, taskUuid } = matchingEntry;

      if (taskUuid) {
        const path = callUuid
          ? getCallPageUrl(orgName, taskUuid, callUuid)
          : getTaskPageUrl(orgName, taskUuid);
        newCallOrTaskUrl = `${getOperatorPortalUrl()}${path}`;
      }
    }

    setCallOrTaskUrl(newCallOrTaskUrl);
  }, [manualEntry, recentCallChoices, value]);

  const filteredCallHistory = useMemo(() => {
    let filteredItems = callHistory;

    if (manualEntry && !filteredItems?.find((item) => item.id === manualEntry?.id)) {
      filteredItems = [manualEntry, ...(filteredItems || [])];
    }

    if (filter.type) {
      const [calls, tasks] = partition(callHistory, (item) => item.callUuid);
      filteredItems = filter.type === 'call' ? calls : tasks;
    }

    if (filter.orgUuid) {
      filteredItems = filteredItems?.filter((item) => item.orgUuid === filter.orgUuid);
    }

    if (filter.excludeTaskUuids?.length) {
      filteredItems = filteredItems?.filter(
        (item) => !filter.excludeTaskUuids?.includes(item.taskUuid)
      );
    }

    if (filter.taskState) {
      filteredItems = filteredItems?.filter((item) => item.task?.state === filter.taskState);
    }

    if (filter.taskType) {
      filteredItems = filteredItems?.filter((item) => item.task?.taskType === filter.taskType);
    }

    if (filter.excludeProductionOrgs || filter.excludeTestingDisabledOrgs) {
      const match: Partial<UserOrg> = {};

      if (filter.excludeProductionOrgs) {
        match.live = false;
      }

      if (filter.excludeTestingDisabledOrgs) {
        match.test = true;
      }

      const matchFn = matches(match);

      filteredItems = filteredItems?.filter((item) =>
        matchFn(orgs.filter((org) => org.name === item.orgName))
      );
    }

    if (manualEntry && !filteredItems?.find((item) => item.id === manualEntry.id)) {
      enqueueSnackbar(
        'The item at the provided URL was found, but does not match the filter criteria.',
        { variant: 'warning' }
      );
    }

    return filteredItems;
  }, [
    callHistory,
    enqueueSnackbar,
    filter.excludeProductionOrgs,
    filter.excludeTaskUuids,
    filter.excludeTestingDisabledOrgs,
    filter.orgUuid,
    filter.taskState,
    filter.taskType,
    filter.type,
    manualEntry,
    orgs,
  ]);

  const recentCallFilterOptions = createFilterOptions({
    stringify: ({ data }: Choice<string, RecentCallEntry>) => {
      const { orgName, callUuid, taskUuid, task } = data || {};
      const parts: string[] = [`Task ${taskUuid || ''}`];

      if (orgName) {
        parts.push(getOrgInfo(orgs, orgName)?.displayName);
      }

      if (task?.taskType) {
        parts.push(getTaskTypeDisplayName(task.taskType));
      }

      if (task?.bvInputs?.payerInfo) {
        parts.push(`Payer ${task.bvInputs.payerInfo.name}`);
      }

      if (callUuid) {
        parts.push(`Call ${callUuid}`);
      }

      return parts.filter((part) => part).join(' ');
    },
  });

  const getField = useCallback(
    (field: InfoField, historyEntry: RecentCallEntry) => {
      const { orgName, task } = historyEntry;
      switch (field) {
        case 'orgName':
          return getOrgInfo(orgs, orgName)?.displayName || orgName;
        case 'taskType':
        default:
          return task ? getTaskTypeDisplayName(task.taskType) : 'Unknown';
      }
    },
    [orgs]
  );

  const loadManualCallOrTask = useCallback(
    async ({
      callUuid,
      orgName,
      taskUuid,
    }: Pick<RecentCallEntry, 'callUuid' | 'orgName' | 'taskUuid'>) => {
      // Check existing entries
      const entryId = getRecentCallEntryId({ taskUuid, callUuid });
      const matchingEntry = recentCallChoices.find(({ value }) => value === entryId);
      if (matchingEntry) {
        onChange(entryId);
        (document.activeElement as HTMLInputElement)?.blur?.();
        return;
      }

      // Load task doc and create entry
      setIsLoading(true);
      try {
        const { uuid: orgUuid } = getOrgInfo(orgs, orgName);
        const {
          data: { task },
        } = await client.query({
          query: GetTaskDocument,
          variables: {
            orgUuid,
            taskUuid,
          },
        });
        const logEntry = {
          orgName,
          orgUuid,
          taskUuid,
          callUuid,
          task,
          uid: user?.uid || null,
        };
        void RecentCallsService.log(logEntry);
        onChange(entryId);
        setManualEntry({
          ...logEntry,
          id: entryId,
          timestampMillis: Date.now(),
        });
        // Close
        (document.activeElement as HTMLInputElement)?.blur?.();
      } catch (error) {
        enqueueSnackbar(`Failed to manually select call or task: ${error}`, {
          variant: 'error',
        });
      }
      setIsLoading(false);
    },
    [client, enqueueSnackbar, onChange, orgs, recentCallChoices, user?.uid]
  );

  // Handle manual URL input
  const onUrlChange = useCallback(
    (inputValue: string) => {
      const matches = TASK_OR_CALL_URL_EXP.exec(inputValue);
      if (!matches) {
        setIsLoading(false);
        if (value) {
          onChange('');
        }
      }

      setCallOrTaskUrl(inputValue);

      if (matches) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, orgName, taskUuid, callUuid] = matches;
        void loadManualCallOrTask({ callUuid, orgName, taskUuid });
      }
    },
    [loadManualCallOrTask, onChange, value]
  );

  const recentCallToChoice: (historyEntry: RecentCallEntry) => Choice<string, RecentCallEntry> =
    useCallback(
      (historyEntry: RecentCallEntry) => {
        const { id: entryId, taskUuid, task, callUuid, timestampMillis } = historyEntry;
        const payerInfo = task?.bvInputs?.payerInfo;
        const timeAgo = dayjs.tzo(timestampMillis).fromNow();
        const isViewingEntry = entryId === currentEntryId;
        const isPresentOnActiveEntry = callUuid === activeCallId && isConnectedToCall;

        return {
          label: (
            <div
              style={{
                alignItems: 'stretch',
                display: 'flex',
                flexGrow: 1,
              }}
            >
              <div style={{ flexGrow: 1 }}>
                {callUuid && (
                  <Typography
                    sx={{
                      fontSize: 12,
                      marginTop: '.25em',
                      marginBottom: '-.125em',
                      opacity: 0.9,
                    }}
                    variant="body2"
                  >
                    Task {getUuidDisplayName(taskUuid)}
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                  variant="subtitle1"
                >
                  {callUuid
                    ? `Call ${getUuidDisplayName(callUuid)}`
                    : `Task ${getUuidDisplayName(taskUuid)}`}
                  {task?.taskType && ` - ${getField(displayField, historyEntry)}`}
                </Typography>
                <Box
                  sx={{
                    marginTop: '-.125em',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      opacity: 0.9,
                    }}
                    variant="body2"
                  >
                    {getField(subtitleField, historyEntry)}
                    {payerInfo?.name && ` (Payer: ${payerInfo.name})`}
                  </Typography>
                </Box>
              </div>
              <div
                style={{
                  alignItems: 'flex-end',
                  display: 'flex',
                  flex: '0 0 auto',
                }}
              >
                {isPresentOnActiveEntry ? (
                  <Chip color="success" label="Active" size={ShirtSizes.SM} variant="outlined" />
                ) : isViewingEntry ? (
                  <Chip color="primary" label="Viewing" size={ShirtSizes.SM} variant="outlined" />
                ) : (
                  <Typography
                    sx={{
                      fontSize: 12,
                      opacity: 0.75,
                    }}
                    variant="body2"
                  >
                    {timeAgo}
                  </Typography>
                )}
              </div>
            </div>
          ),
          value: getRecentCallEntryId({ taskUuid, callUuid }),
          data: historyEntry,
        };
      },
      [currentEntryId, activeCallId, isConnectedToCall, getField, displayField, subtitleField]
    );

  useEffect(() => {
    setRecentCallChoices((filteredCallHistory || []).map(recentCallToChoice));
  }, [filteredCallHistory, recentCallToChoice, isConnectedToCall]);

  useEffect(() => {
    const activeOperators = callState?.operators ?? [];
    const didJoinCall =
      activeOperators.find(
        ({ isPresentOnCall, operatorEmail }) => operatorEmail === user?.email && isPresentOnCall
      ) !== undefined;

    setIsConnectedToCall(didJoinCall && callState?.audioEndMillis === 0);
  }, [callState?.operators, callState?.audioEndMillis, user]);

  return (
    <Stack spacing={2}>
      <Autocomplete
        blurOnSelect
        choices={recentCallChoices}
        disableClearable={true}
        error={isCallOrTaskUrlInvalid}
        filterOptions={recentCallFilterOptions}
        fullWidth
        getOptionLabel={(option) => {
          const choice =
            typeof option !== 'string'
              ? option
              : recentCallChoices.find(({ value }) => value === option);

          if (!choice?.data) {
            return '';
          }

          const { id: entryId, callUuid, taskUuid, task } = choice.data;

          const parts: string[] = [
            callUuid
              ? `Call ${getUuidDisplayName(callUuid)}`
              : `Task ${getUuidDisplayName(taskUuid)}`,
          ];

          if (task?.taskType) {
            parts.push(`- ${getField(displayField, choice.data)}`);
          }

          if (isConnectedToCall && activeCallId === callUuid) {
            parts.push(`(active call)`);
          } else if (entryId === currentEntryId) {
            parts.push(`(viewing ${callUuid ? 'call' : 'task'})`);
          }

          return parts.join(' ');
        }}
        label={label}
        loading={isLoading}
        loadingText="Loading call/task data..."
        noOptionsText="No matching calls/tasks"
        onChange={onChange}
        size={ShirtSizes.SM}
        value={value}
        variant="filled"
      />
      <TextField
        disabled={isLoading}
        endAdornment={
          <InputAdornment
            position="end"
            sx={{
              marginInlineEnd: -1,
              opacity: callOrTaskUrl === '' ? 0 : 1,
              pointerEvents: callOrTaskUrl === '' ? 'none' : 'auto',
              transition: `opacity ${MuiTheme.transitions.duration.short} linear`,
            }}
          >
            <IconButton
              iconName={isLoading ? undefined : IconNames.CLOSE}
              onClick={() => onUrlChange('')}
              title={'Clear'}
            >
              {isLoading && <CircularProgress size={24} />}
            </IconButton>
          </InputAdornment>
        }
        InputLabelProps={{
          error: isCallOrTaskUrlInvalid,
        }}
        InputProps={{
          error: isCallOrTaskUrlInvalid,
        }}
        label={`${label} URL`}
        onChange={onUrlChange}
        value={callOrTaskUrl}
        variant="filled"
      />
    </Stack>
  );
}
