import { createContext } from 'react';

import { infinitusai } from '@infinitus/proto/pbjs';

interface AudioConstraints {
  autoGainControl: boolean;
  echoCancellation: boolean;
  noiseSuppression: boolean;
}

interface JoinAudioSettings extends AudioConstraints {
  microphoneDeviceId?: string;
  muteMicByDefault: boolean;
  speakerDeviceId?: string;
}

interface AudioInputConstraintUpdates extends AudioConstraints {}

interface JoinArgs extends JoinAudioSettings {
  callUuid: string;
  orgUuid: string;
  taskUuid: string;
}

export interface LiveKitClient {
  executeAction: (data: infinitusai.be.LivekitEvent) => Promise<void>;
  /**
   * Join new room
   */
  join: (args: JoinArgs) => void;

  /**
   * Leave current room
   */
  leave: () => void;

  /**
   * Update the audio input device
   *
   * @param deviceId - The deviceId of the audio input device
   * @returns void
   */
  setAudioInputDevice: (deviceId: string) => Promise<void>;

  /**
   * Update the audio output device
   *
   * @param deviceId - The deviceId of the audio output device
   * @returns void
   *
   * @note This method will not work in safari because `setSinkId` is not
   * supported in Safari. See compatibility: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId#browser_compatibility
   */
  setAudioOutputDevice: (deviceId: string) => Promise<void>;

  /**
   * Updates audio input constraints for the current microphone input
   *
   * @param audioInputConstraintUpdates - The updates for the audio input device, including constraints
   * @returns void
   */
  updateAudioInputConstraints: (
    audioInputConstraintUpdates: AudioInputConstraintUpdates
  ) => Promise<void>;
}

const stub = () => {
  throw new Error('not implemented');
};

const DEFAULT_LIVEKIT_CLIENT: LiveKitClient = {
  join: stub,
  leave: stub,
  executeAction: stub,
  setAudioInputDevice: stub,
  setAudioOutputDevice: stub,
  updateAudioInputConstraints: stub,
};

export const LIVEKIT_SERVER = 'wss://outbound-9zve9lcc.livekit.cloud';

export const LiveKitClientProvider = createContext<LiveKitClient>(DEFAULT_LIVEKIT_CLIENT);
