import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { expandedSize, collapsedSize } from 'layout/LayoutConstants';
import { useRef, useId } from 'react';

import { useNavContext } from './NavContext';
import NavItem from './subcomponents/NavItem';
import { SortableNavItem } from './subcomponents/SortableNavItem';
import { NavItemWithHref } from './types';

interface AdminNavLayoutProps {
  callPageMatch: boolean;
  filteredNavList: NavItemWithHref[];
  isExpanded: boolean;
  searchOpen: boolean;
  searchTerm: string;
  setSearchOpen: (open: boolean) => void;
  setSearchTerm: (term: string) => void;
  setShowAllPages: (show: boolean) => void;
  showAllPages: boolean;
}

interface FavoritesSectionProps {
  favorites: NavItemWithHref[];
  handleDragEnd: (oldIndex: number, newIndex: number) => void;
  isExpanded: boolean;
}

interface AllPagesSectionProps {
  callPageMatch: boolean;
  displayAllPages: boolean;
  filteredNavList: NavItemWithHref[];
  isExpanded: boolean;
  searchInputId: string;
  searchInputRef: React.RefObject<HTMLInputElement>;
  searchOpen: boolean;
  searchTerm: string;
  setSearchOpen: (open: boolean) => void;
  setSearchTerm: (term: string) => void;
  setShowAllPages: (show: boolean) => void;
  showAllPages: boolean;
}

const FavoritesSection = ({ isExpanded, favorites, handleDragEnd }: FavoritesSectionProps) => {
  const pointerSensor = useSensor(PointerSensor);
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });
  const sensors = useSensors(pointerSensor, keyboardSensor);

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = favorites.findIndex((item) => item.id === active.id);
    const newIndex = favorites.findIndex((item) => item.id === over.id);

    handleDragEnd(oldIndex, newIndex);
  };

  return (
    <Box sx={{ maxHeight: '50%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ ml: 1.5, pt: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1">{isExpanded ? 'Favorites' : 'Favs'}</Typography>
      </Box>
      <List
        sx={{
          overflow: 'auto',
          '&::-webkit-scrollbar-track': { background: 'transparent' },
          '&::-webkit-scrollbar-thumb': {
            border: 'none',
            '&:hover': { backgroundColor: 'lightgray' },
          },
        }}
      >
        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} sensors={sensors}>
          <SortableContext
            items={favorites.map((item) => item.id)}
            strategy={verticalListSortingStrategy}
          >
            {favorites.map((item, index) => (
              <SortableNavItem
                index={index}
                isExpanded={isExpanded}
                isReorderable={true}
                item={item}
                key={item.id}
              />
            ))}
          </SortableContext>
        </DndContext>
      </List>
    </Box>
  );
};

const AllPagesSection = ({
  isExpanded,
  searchOpen,
  searchTerm,
  searchInputRef,
  searchInputId,
  showAllPages,
  displayAllPages,
  callPageMatch,
  filteredNavList,
  setSearchOpen,
  setSearchTerm,
  setShowAllPages,
}: AllPagesSectionProps) => (
  <>
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0,
      }}
    >
      {searchOpen ? (
        <TextField
          autoFocus
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  setSearchOpen(false);
                  setSearchTerm('');
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            ),
          }}
          inputRef={searchInputRef}
          key={searchInputId}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search pages..."
          size="small"
          sx={{
            width: '100%',
            ml: 1,
            display: 'flex',
            '& .MuiOutlinedInput-root': { bgcolor: 'background.paper' },
          }}
          value={searchTerm}
        />
      ) : (
        <>
          <Stack
            alignItems={isExpanded ? 'left' : 'center'}
            ml={isExpanded ? 1.5 : 0}
            width={isExpanded ? expandedSize : collapsedSize}
          >
            <Typography variant="body1">{isExpanded ? 'All Pages' : 'All'}</Typography>
            {!isExpanded && (
              <IconButton onClick={() => setShowAllPages(!showAllPages)}>
                {showAllPages ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            )}
          </Stack>
          {!callPageMatch && (
            <IconButton onClick={() => setSearchOpen(true)}>
              <SearchIcon />
            </IconButton>
          )}
        </>
      )}
    </Box>
    {displayAllPages && (
      <List
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          '&::-webkit-scrollbar-track': { background: 'transparent' },
          '&::-webkit-scrollbar-thumb': {
            border: 'none',
            '&:hover': { backgroundColor: 'lightgray' },
          },
        }}
      >
        {filteredNavList.map((item, index) => (
          <NavItem
            index={index}
            isExpanded={isExpanded}
            isReorderable={false}
            item={item}
            key={item.id}
          />
        ))}
      </List>
    )}
  </>
);

function AdminNavLayout({
  callPageMatch,
  filteredNavList,
  isExpanded,
  searchOpen,
  searchTerm,
  setSearchOpen,
  setSearchTerm,
  setShowAllPages,
  showAllPages,
}: AdminNavLayoutProps) {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchInputId = useId();
  const displayAllPages = isExpanded || showAllPages;

  const { favorites, updateFavorites } = useNavContext();

  const handleDragEnd = (oldIndex: number, newIndex: number) => {
    const newFavorites = arrayMove(favorites, oldIndex, newIndex);
    updateFavorites(newFavorites);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      {favorites.length > 0 ? (
        <>
          <FavoritesSection
            favorites={favorites}
            handleDragEnd={handleDragEnd}
            isExpanded={isExpanded}
          />
          <Divider />
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
            <AllPagesSection
              callPageMatch={callPageMatch}
              displayAllPages={displayAllPages}
              filteredNavList={filteredNavList}
              isExpanded={isExpanded}
              searchInputId={searchInputId}
              searchInputRef={searchInputRef}
              searchOpen={searchOpen}
              searchTerm={searchTerm}
              setSearchOpen={setSearchOpen}
              setSearchTerm={setSearchTerm}
              setShowAllPages={setShowAllPages}
              showAllPages={showAllPages}
            />
          </Box>
        </>
      ) : (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
          <AllPagesSection
            callPageMatch={callPageMatch}
            displayAllPages={displayAllPages}
            filteredNavList={filteredNavList}
            isExpanded={isExpanded}
            searchInputId={searchInputId}
            searchInputRef={searchInputRef}
            searchOpen={searchOpen}
            searchTerm={searchTerm}
            setSearchOpen={setSearchOpen}
            setSearchTerm={setSearchTerm}
            setShowAllPages={setShowAllPages}
            showAllPages={showAllPages}
          />
        </Box>
      )}
    </Box>
  );
}

export { AdminNavLayout };
