import List from '@mui/material/List';

import NavItem from './subcomponents/NavItem';
import { NavItemWithHref } from './types';

interface OperatorNavLayoutProps {
  filteredNavList: NavItemWithHref[];
  isExpanded: boolean;
}

const OperatorNavLayout = ({ filteredNavList, isExpanded }: OperatorNavLayoutProps) => {
  return (
    <List sx={{ flexGrow: 1, overflow: 'auto' }}>
      {filteredNavList.map((item, index) => (
        <NavItem index={index} isExpanded={isExpanded} item={item} key={item.id} />
      ))}
    </List>
  );
};

export { OperatorNavLayout };
