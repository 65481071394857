import { useAuth, AuthRequired } from '@infinitusai/auth';
import {
  SigninPage,
  SignoutPage,
  LandingPage,
  DefaultSigninPage,
  Page,
  NotFoundPage,
  useAppState,
} from '@infinitusai/shared';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';
import { Suspense, useState } from 'react';
import Helmet from 'react-helmet';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useClientEventScope } from '@infinitus/hooks/useLogBuffer';
import { infinitusai } from '@infinitus/proto/pbjs';
import CheckVersion from 'components/CheckVersion';
import { ConfirmProvider } from 'components/Confirm';
import DevelopmentPanel from 'components/DevelopmentPanel';
import Layout from 'layout';
import InvitePage from 'pages/invite';
import PortalPage from 'pages/portal';
import MuiTheme from 'styles/MuiTheme';
import './App.css';
import {
  getBackendServerUrl,
  getBuildVersion,
  getGcpProjectId,
  getNodeEnv,
  getPresenceWebsocketUrl,
  isCypress,
} from 'utils/environments';

import ErrorBoundary from './Error';
import LogForwarding from './LogForwarding';
import OldOperatorRoute from './OldOperatorRoute';

const signinTheme = createTheme({
  // customize the theme specifically for the CustomComponent
  // and provide default prop values that are specific to the CustomComponent's needs
  palette: {
    mode: 'dark',
    wavesurfer: {
      cursor: '',
      playhead: '',
      progress: '',
    },
  },
  components: {
    MuiGrid: {
      defaultProps: {
        columnSpacing: undefined,
        rowSpacing: undefined,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: 'static',
          backgroundImage: 'none',
          boxShadow: 'none',
          textDecoration: 'none',
          backgroundColor: '#303030',
          '& a': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: 2,
          input: {
            color: 'black',
          },
        },
      },
      defaultProps: {
        maxRows: 3,
        size: 'small',
      },
    },

    // ... other overrides for CustomComponent if necessary
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    caption: {
      fontWeight: 300,
      letterSpacing: -0.25,
    },
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    h4: {},
    h5: {},
    h6: {},
  },
});

// Use this UUID as a way to uniquely identify this browser tab session
export const browserSessionUuid = crypto.randomUUID();

const DEVELOPMENT_PANEL_SUPPORTED =
  getNodeEnv() === 'development' && window.performance && window.performance.memory;

getBuildVersion() &&
  console.log(
    `Loading Infinitus Portal with session id of '${browserSessionUuid}', version '${getBuildVersion()}' for user-agent '${
      window.navigator.userAgent
    }'`
  );
console.log(
  `Using backend URL '${getBackendServerUrl()}', websocket URL '${getPresenceWebsocketUrl()}', gcloud project ${getGcpProjectId()}`
);

function App() {
  const auth = useAuth();
  const { orgName } = useAppState();
  const { pathname, search } = useLocation();

  useClientEventScope();
  const [showDevelopmentPanel, setShowDevelopmentPanel] = useState(false);
  const oldPortalRoutes = ['queue', 'admin/*', 'account', 'speedTest'];

  return (
    <ErrorBoundary>
      <Helmet defaultTitle="Infinitus Portal" titleTemplate="%s | Infinitus Portal" />
      <CheckVersion />
      <Suspense fallback={null}>
        {!isCypress() ? <LogForwarding /> : null}
        <ThemeProvider theme={MuiTheme}>
          <CssBaseline />
          <ConfirmProvider>
            <Routes>
              {/* <SigninPage> will be open when there are no additional path segments or query parameters */}
              <Route
                element={
                  <ThemeProvider theme={signinTheme}>
                    <SigninPage backgroundImageUrl="/assets/new-signin-background.png" />
                  </ThemeProvider>
                }
                path="/login"
              >
                {/* <DefaultSigninPage> will be open when there are additional path segments or query parameters */}
                <Route
                  element={
                    <DefaultSigninPage
                      popupSignIn={getNodeEnv() === 'development'}
                      providers={[
                        infinitusai.auth.AuthProvider.AUTH_PROVIDER_GOOGLE.toString(),
                        infinitusai.auth.AuthProvider.AUTH_PROVIDER_EMAIL.toString(),
                      ]}
                    />
                  }
                  index
                />
              </Route>
              <Route element={<SignoutPage />} path="/logout" />
              <Route
                element={
                  <AuthRequired
                    auth={auth}
                    unauthorized={<Navigate replace to={`/login?from=${pathname}${search}`} />}
                  >
                    <Layout />
                  </AuthRequired>
                }
                path="/"
              >
                <Route
                  element={orgName ? <Navigate to={`/${orgName}`} /> : <LandingPage />}
                  index
                />
                <Route
                  element={
                    <Page sx={{ p: 0 }} title="Invite">
                      <InvitePage />
                    </Page>
                  }
                  path="invite/:uuid"
                />
                {/* Redirects from previous portal routes to :orgName/* routes */}
                {oldPortalRoutes.map((path) => (
                  <Route
                    element={<Navigate to={`/${orgName || 'infinitus'}${pathname}${search}`} />}
                    key={path}
                    path={path}
                  />
                ))}
                <Route element={<OldOperatorRoute />} path="operator/:org/*" />
                <Route element={<PortalPage />} path=":orgName/*" />
                <Route element={<NotFoundPage />} path="*" />
              </Route>
            </Routes>
          </ConfirmProvider>
        </ThemeProvider>
      </Suspense>
      {DEVELOPMENT_PANEL_SUPPORTED && showDevelopmentPanel && (
        <DevelopmentPanel onClick={() => setShowDevelopmentPanel(!showDevelopmentPanel)} />
      )}
    </ErrorBoundary>
  );
}

export default App;
