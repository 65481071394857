import { SxProps } from '@mui/material';

import MuiTheme from 'styles/MuiTheme';

import { expandTransitionDuration, expandedSize, sizeDiff } from '../LayoutConstants';

export const baseDrawerSx: SxProps<typeof MuiTheme> = {
  backgroundColor: 'black',
  boxShadow: 19,
  overflowX: 'hidden',
  overflowY: 'auto',
  borderRight: '1px solid #363636',
  paddingBlockStart: 1,
  position: 'fixed',
  insetBlockEnd: 0,
  insetBlockStart: 0,
  insetInlineStart: `-${sizeDiff}px`,
  scrollbarGutter: 'stable',
  transform: `translate3d(0,0,0)`,
  transitionDuration: expandTransitionDuration,
  width: `${expandedSize}px`,
  zIndex: MuiTheme.zIndex.appBar - 1,
  marginTop: '48px',
  height: `calc(100% - 48px)`,
  '& .MuiDrawer-paper': {
    top: '48px',
    width: `${expandedSize}px`,
    transition: MuiTheme.transitions.create('width', {
      easing: MuiTheme.transitions.easing.sharp,
      duration: MuiTheme.transitions.duration.enteringScreen,
    }),
  },

  '&::-webkit-scrollbar': {
    width: 8,
  },
};

export const drawerOpenSx: SxProps<typeof MuiTheme> = {
  transform: `translate3d(${sizeDiff}px,0,0)`,
};
