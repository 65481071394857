import { useAuth, UserType } from '@infinitusai/auth';
import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import NewAppNav from 'layout/NewAppNav';
import { useEffect, useState } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useMatch } from 'react-router-dom';

import useConditionalSx from '@infinitus/hooks/useConditionalSx';
import MuiTheme from 'styles/MuiTheme';

import { baseDrawerSx, drawerOpenSx } from './NewDrawerStyles';

interface DrawerProps {
  onMouseLeave: () => void;
}

export function Drawer({ onMouseLeave }: DrawerProps) {
  const isCallPage = useMatch('/:orgName/operator/tasks/:splat/calls/:splat');
  const [isHovered, setHovered] = useState(false);
  const drawerSx = useConditionalSx(baseDrawerSx, [isHovered, drawerOpenSx]);

  const handleMouseLeave = () => {
    if (isHovered) {
      setHovered(false);
      onMouseLeave();
    }
  };

  const handleMouseEnter = () => {
    if (isCallPage) {
      setHovered(false);
    } else {
      setHovered(true);
    }
  };

  useEffect(() => {
    if (isCallPage) {
      setHovered(false);
    }
  }, [isCallPage]);

  const { isAuthenticated, hasUserType } = useAuth();
  const isAuthorized = hasUserType([UserType.OPERATOR, UserType.ADMIN]);

  if (!isAuthenticated || !isAuthorized) return null;

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} sx={drawerSx}>
        <KeyboardEventHandler
          handleEventType="keydown"
          handleFocusableElements={true}
          handleKeys={['esc']}
          onKeyEvent={handleMouseLeave}
        />

        <NewAppNav isExpanded={isHovered} />
      </Box>
    </ThemeProvider>
  );
}
