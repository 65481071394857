import { useAuth } from '@infinitusai/auth';

import {
  applyOperatorPortalNewSideMenuUXParticipant,
  ENABLE_OPERATOR_PORTAL_NEW_SIDE_MENU_UX_COHORT_KEY,
  useExperiment,
} from '@infinitus/hooks/useExperiment';

export function useOperatorPortalNewSideMenuExperiment() {
  const auth = useAuth();
  const { isInCohort, loading: loadingNewSideMenuExperiment } = useExperiment({
    experiment: applyOperatorPortalNewSideMenuUXParticipant(auth.user?.email ?? ''),
  });
  const newSideMenuEnabled = loadingNewSideMenuExperiment
    ? false
    : isInCohort(ENABLE_OPERATOR_PORTAL_NEW_SIDE_MENU_UX_COHORT_KEY);

  return newSideMenuEnabled;
}
