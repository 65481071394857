import { UserOrg } from '@infinitusai/auth';
import { ProviderContext } from 'notistack';

import { HeartbeatMessageType } from '@infinitus/hooks/useOperatorPresence/types';
import { infinitusai } from '@infinitus/proto/pbjs';
import { getOrgReadyPageUrl } from 'utils';

type HandleOnOperatorShouldLeaveCall = {
  data: infinitusai.be.OperatorShouldLeaveCallMessage;
  enqueueSnackbar: ProviderContext['enqueueSnackbar'];
  getHeartbeatMessageType: () => HeartbeatMessageType;
  getOrgNameFromUuid: (orgs: UserOrg[], orgUuid: string) => string;
  navigate: (url: string) => void;
  orgs: UserOrg[];
  userEmail: string;
};
export function handleOnOperatorShouldLeaveCall({
  data,
  enqueueSnackbar,
  getHeartbeatMessageType,
  orgs,
  getOrgNameFromUuid,
  navigate,
  userEmail,
}: HandleOnOperatorShouldLeaveCall) {
  console.log(
    `Received an OperatorShouldLeaveCallMessage with payload ${JSON.stringify(
      data
    )} via the websocket connection...`
  );
  // Check if user is on call the call page
  if (getHeartbeatMessageType() !== HeartbeatMessageType.CALL_PAGE) {
    console.log('Ignoring OperatorShouldLeaveCallMessage b/c the user is not on the ready page');
    return;
  }
  // Check if the recipient
  if (data.operatorEmail !== userEmail) {
    console.error(
      `Received a server websocket message to leave call='${data.callUuid}' for operator '${data.operatorEmail}', but we are signed in as '${userEmail}'`
    );
    return;
  }
  // Check if the recipient has access to the org
  const orgName = getOrgNameFromUuid(orgs, data.orgUuid);
  if (!orgName) {
    const errorMsg = `Attempted to redirect operator ${userEmail} to ready page in the org ${data.orgUuid}, which they are not authorized in.`;
    console.error(errorMsg);
    enqueueSnackbar(errorMsg, {
      variant: 'error',
    });
    return;
  }

  console.log('Processing the OperatorShouldLeaveCallMessage');

  console.log(
    `Redirecting operator to ready page for orgUuid=${data.orgUuid} in response to a server websocket message`
  );

  // Note that there is a logic in Operator Portal that redirects the user to unified queue ready page
  // upon landing on org specific ready page if the unified queue is enable for that org
  const orgSpecificReadyPageUrl = getOrgReadyPageUrl(orgName);
  navigate(orgSpecificReadyPageUrl);
}
