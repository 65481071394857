import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { NavItemWithHref } from '../types';
import NavItem from './NavItem';

interface SortableNavItemProps {
  index: number;
  isExpanded: boolean;
  isReorderable: boolean;
  item: NavItemWithHref;
}

export function SortableNavItem({ item, isExpanded, isReorderable, index }: SortableNavItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <NavItem
        dragHandleProps={{
          ...attributes,
          ...listeners,
        }}
        index={index}
        isDragging={isDragging}
        isExpanded={isExpanded}
        isReorderable={isReorderable}
        item={item}
      />
    </div>
  );
}
