import { PermissionName, useAuth, UserType } from '@infinitusai/auth';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { Link as RouterLink, useMatch } from 'react-router-dom';

import { IconButton } from '@infinitus/components/Button';
import { Icon, IconNames } from '@infinitus/components/Icon';
import { Tooltip } from '@infinitus/components/Tooltip';
import useConditionalSx from '@infinitus/hooks/useConditionalSx';
import ShirtSizes from '@infinitus/types/shirt-sizes';

import { useNavContext } from '../NavContext';
import { Props } from '../types';
import {
  activeLabelSx,
  activeLinkSx,
  baseLabelSx,
  baseLinkSx,
  expandedLabelSx,
} from './NavItemStyles';

function NavItem({
  isExpanded,
  item,
  isDragging,
  isReorderable,
  dragHandleProps,
  ...props
}: Props) {
  const { hasUserType, hasPermission } = useAuth();
  const { addToFavorites, removeFromFavorites, isFavorite } = useNavContext();
  const [isHovered, setIsHovered] = useState(false);

  const { iconName, iconOutlined, title, tooltip, id, href } = item;
  const match = useMatch(`${href}/*`);

  // Compute states
  const isActive = Boolean(match);
  const isAdmin =
    hasUserType([UserType.ADMIN]) && hasPermission([PermissionName.ADMIN_PORTAL_ACCESS]);
  const isFavorited = isFavorite(id);
  const isDraggable = isFavorited && isReorderable;

  const handleFavoriteClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!id) return;

      if (isFavorited) {
        removeFromFavorites(id);
      } else {
        addToFavorites(item);
      }
    },
    [id, item, isFavorited, removeFromFavorites, addToFavorites]
  );

  return (
    <MuiLink
      component={RouterLink}
      draggable={false}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        ...useConditionalSx(baseLinkSx, [isActive, activeLinkSx]),
        '& .MuiIconButton-root': {
          display: 'none',
          marginLeft: 'auto',
          ...(isFavorited ? { display: 'inline-flex' } : {}),
        },
        '&:hover .MuiIconButton-root': {
          display: 'inline-flex',
        },
        cursor: isHovered ? 'pointer' : 'default',
        opacity: isDragging ? 0.5 : 1,
      }}
      to={href}
      underline="none"
    >
      <div>
        {isDraggable && isExpanded && isHovered ? (
          <div {...dragHandleProps}>
            <Icon
              name={IconNames.DRAG_HANDLE}
              size={ShirtSizes.MD}
              sx={{ marginBlockStart: 0.75, cursor: isDraggable ? 'grabbing' : 'default' }}
            />
          </div>
        ) : (
          <IconWithTooltip
            name={iconName}
            outlined={iconOutlined}
            size={ShirtSizes.MD}
            sx={{ marginBlockStart: 0.75, marginInlineStart: undefined }}
            title={!isExpanded ? title : undefined}
          />
        )}
      </div>

      <Typography
        sx={useConditionalSx(baseLabelSx, [isActive, activeLabelSx], [isExpanded, expandedLabelSx])}
        variant="body1"
      >
        {tooltip && isExpanded ? <Tooltip title={tooltip}>{title}</Tooltip> : title}
      </Typography>

      {isAdmin && (
        <IconButton
          color={isFavorited ? 'primary' : 'white'}
          hideTooltip
          iconName={isFavorited ? IconNames.STAR : IconNames.STAR_OUTLINE}
          iconSize={ShirtSizes.SM}
          onClick={handleFavoriteClick}
          size="small"
          title="Toggle favorite"
        />
      )}
    </MuiLink>
  );
}

const IconWithTooltip = ({
  name,
  outlined,
  size,
  title,
  sx,
}: {
  name: IconNames;
  outlined: boolean | undefined;
  size: ShirtSizes;
  sx: SxProps;
  title: string | undefined;
}) => {
  const icon = <Icon name={name} outlined={outlined} size={size} sx={sx} />;
  return title ? (
    <Tooltip placement="right" title={title}>
      {icon}
    </Tooltip>
  ) : (
    icon
  );
};

export default NavItem;
