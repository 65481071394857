import { AppName } from '@infinitusai/api';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '@infinitus/components/Button';
import { Icon, IconNames } from '@infinitus/components/Icon';
import { Tooltip } from '@infinitus/components/Tooltip';
import { ClientEventType } from '@infinitus/generated/frontend-common';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import SpeedTestService, { SpeedTestRunResults } from '@infinitus/services/SpeedTestService';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import { Page } from 'components/Page';
import { getBackendServerUrl, getFrontendVersion } from 'utils/environments';

function formatBytes(bytes: number): [number | string, string] {
  if (bytes < 1000) {
    return [bytes, 'Bytes']; // No conversion to KB for less than 1000 bytes
  } else if (bytes < 1000 * 1000) {
    return [(bytes / 1000).toFixed(2), 'Kbs']; // Convert to KB if less than 1 MB
  } else {
    return [(bytes / 1000 / 1000).toFixed(2), 'Mbs']; // Convert to MB otherwise
  }
}

export default function SpeedTestPage() {
  const [speedTestResults, setSpeedTestResults] = useState<SpeedTestRunResults>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const runTest = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      setSpeedTestResults(undefined);
      const results = await new SpeedTestService(
        AppName.OPERATOR,
        getBackendServerUrl(),
        getFrontendVersion()
      ).runTest();
      void logEventToBigQuery({
        clientEventType: ClientEventType.PERFORMANCE_MEASUREMENT,
        message: 'Speed test completed',
        meta: results,
      });
      setSpeedTestResults(results);
    } catch (e: any) {
      console.error(e.message);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    void runTest();
  }, [runTest]);

  return (
    <Page breadcrumbs={[{ title: 'Speed Test' }]}>
      <Stack alignItems="center" direction="row" marginBottom={2} spacing={2}>
        <Typography variant="h4">Results</Typography>{' '}
        <Tooltip
          title={
            'The measurements are against Infinitus infrastructure. Measurements may not accurately reflect network quality and performance, but is useful for comparison.'
          }
        >
          <Icon
            name={IconNames.HELP}
            outlined={true}
            size={ShirtSizes.SM}
            sx={{ display: 'inline', marginInlineStart: 0.5, verticalAlign: 'middle' }}
          />
        </Tooltip>
        <Button disabled={loading} onClick={() => runTest()} text="Retest" />
      </Stack>
      {loading && <div>running test...</div>}
      {!!error && <div>error: {JSON.stringify(error)}</div>}
      {speedTestResults && (
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: `repeat(3, 1fr)`,
            gap: theme.spacing(2),
            maxWidth: 800,
          })}
        >
          {speedTestResults.roundTripTimeResults !== undefined ? (
            <SpeedTestResultPanel
              title="Round Trip Time"
              units="ms"
              value={Math.round(speedTestResults.roundTripTimeResults.average)}
            />
          ) : null}
          {speedTestResults.downloadSpeedResults !== undefined ? (
            <SpeedTestResultPanel
              title="Download"
              units={formatBytes(speedTestResults.downloadSpeedResults.average)[1]}
              value={formatBytes(speedTestResults.downloadSpeedResults.average)[0]}
            />
          ) : null}
          {speedTestResults.uploadSpeedResults !== undefined ? (
            <SpeedTestResultPanel
              title="Upload"
              units={formatBytes(speedTestResults.uploadSpeedResults.average)[1]}
              value={formatBytes(speedTestResults.uploadSpeedResults.average)[0]}
            />
          ) : null}
        </Box>
      )}
    </Page>
  );
}

interface SpeedTestResultPanelProps {
  title: string;
  units: string;
  value: number | string;
}

function SpeedTestResultPanel({ title, units, value }: SpeedTestResultPanelProps) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography color="textSecondary" variant="subtitle1">
          {title}
        </Typography>
        <div>
          <Typography component="span" variant="h6">
            {value}
          </Typography>{' '}
          <Typography color="textSecondary" component="span" variant="subtitle1">
            {units}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
