import { CohortInput, ExperimentInput } from '@infinitus/generated/frontend-common';

export const DISABLE_OPERATOR_PORTAL_NEW_SIDE_MENU_UX_COHORT_KEY = 'control';
export const ENABLE_OPERATOR_PORTAL_NEW_SIDE_MENU_UX_COHORT_KEY = 'treatment';

// All participants will be in the control cohort unless explicitly assigned to
// the treatment cohort, which means the default group will be control group.
const cohortDistribution: CohortInput[] = [
  { cohortName: DISABLE_OPERATOR_PORTAL_NEW_SIDE_MENU_UX_COHORT_KEY, weight: 1 },
  { cohortName: ENABLE_OPERATOR_PORTAL_NEW_SIDE_MENU_UX_COHORT_KEY, weight: 0 },
];

export function applyOperatorPortalNewSideMenuUXParticipant(userEmail: string): ExperimentInput {
  const experimentName = 'operator_portal_new_side_menu_ux';

  // participantID should be unique per experimental subject - i.e. if we want to apply treatments to calls;
  // each call should have a distinct participantID. The sample below will do the trick but is a bit overkill.
  const participantID = userEmail;

  return {
    participantID,
    experimentName,
    cohortDistribution,
  };
}
