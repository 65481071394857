import { fetchApi, AppName } from '@infinitusai/api';
import { QueryClient, QueryKey } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';

import { getBackendServerUrl, getFrontendVersion } from './environments';

// Used for react query calls (e.g. useQuery([ '123', 'c', 'getOrg', {}]))
function createQueryApi() {
  return async function queryApi({ queryKey }: { queryKey: QueryKey }) {
    let [orgUuid, apiRoot, apiPath, apiBody] = queryKey;
    const path = `/${apiRoot}/${apiPath}`;
    const body = apiBody || {};

    return fetchApi({
      appName: AppName.OPERATOR,
      backendUrl: getBackendServerUrl(),
      frontendVersion: getFrontendVersion(),
      orgUuid: orgUuid as string,
      user: getAuth().currentUser,
      path,
      body,
    });
  };
}

const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 min
        queryFn: createQueryApi(),
      },
    },
  });

export default createQueryClient;
