import { infinitusai } from '@infinitus/proto/pbjs';
import { NonNullableFields } from '@infinitus/types/utils';
import { HumanIntroReason, RequeueReason, TaskFailReason, TaskType } from 'generated/gql/graphql';

import { getNodeEnv } from './environments';

export const HIGHLIGHT_ITEM_ID = 'HIGHLIGHT_ITEM_ID';
export const CALL_PAGE_OPEN_CATEGORY_LIST_ID = 'CALL_PAGE_OPEN_CATEGORY_ID';
export const CALL_PAGE_TOP_OF_SUGGESTIONS_SECTION_ANCHOR_ID =
  'CALL_PAGE_TOP_OF_SUGGESTIONS_SECTION_ANCHOR_ID';
export const CALL_PAGE_SWITCHES_BAR_ID = 'CALL_PAGE_SWITCHES_BAR_ID';
export const CALL_PAGE_CATEGORY_TABS_ID = 'CALL_PAGE_CATEGORY_TABS_ID';
export const RECORDING_PLAYER_FOOTER = 'RECORDING_PLAYER_FOOTER';
export const CALL_PAGE_OPEN_CATEGORY_CLASS_NAME = 'CALL_PAGE_OPEN_CATEGORY_CLASS_NAME';
export const SUGGESTION_CONTAINER_ROOT = 'SUGGESTION_CONTAINER_ROOT';
export const DEFAULT_APPBAR_HEIGHT = 48;

export enum SuggestionAttributeClass {
  HAS_FLAGGED_FIELD_CLASS = 'HAS_FLAGGED_FIELD_CLASS',
  HAS_MISSING_REQUIRED_FIELD_COUNT_CLASS = 'HAS_MISSING_REQUIRED_FIELD_COUNT_CLASS',
  HAS_VALIDATION_ERROR_COUNT_CLASS = 'HAS_VALIDATION_ERROR_COUNT_CLASS',
  HAS_VALIDATION_WARNING_COUNT_CLASS = 'HAS_VALIDATION_WARNING_COUNT_CLASS',
  HAS_VALIDATION_SUGGESTION_COUNT_CLASS = 'HAS_VALIDATION_SUGGESTION_COUNT_CLASS',
  IS_INCOMPLETE_SUGGESTION_CLASS = 'IS_INCOMPLETE_SUGGESTION_CLASS',
  HAS_PIE_CONFLICT_CLASS = 'HAS_PIE_CONFLICT_CLASS',
}

export enum ResultType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum CallState {
  UNKNOWN,
  IN_PROGRESS,
  COMPLETED,
}

export const JIRA_BASE_URL = 'https://infinitusai.atlassian.net';

// Process Call timeout milliseconds controls the amount of time the operator has to process a call
// before the exclusive lock expires. This is to prevent the multiple operators from concurrently processing the same call.
export const PROCESS_CALL_TIMEOUT_MILLIS = 1000 * 60 * 5; // 5 minutes

export const TEST_ORG_NAMES = ['infinitus', 'infinitus_ivr_hold'];

export const TTS_VOICE_SETTINGS_DEFAULT: Required<
  NonNullableFields<
    Pick<infinitusai.be.ITTSSetting, 'ttsSpeed' | 'ttsPitch' | 'ttsVoice' | 'muteEva'>
  >
> = {
  ttsSpeed: 1.0,
  ttsPitch: -1.6,
  ttsVoice: infinitusai.be.TTSSetting.Voice.en_US_Wavenet_F,
  muteEva: false,
};

export const relevantHumanIntroReasons = [
  HumanIntroReason.HUMAN_INTRO_REASON_VOICE_PAYER_REFUSAL,
  HumanIntroReason.HUMAN_INTRO_REASON_OFFICIAL_PAYER_SOP,
  HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_SUPERVISOR,
  HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_PA_DEPARTMENT,
  HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_TPA,
  HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_OTHER,
  HumanIntroReason.HUMAN_INTRO_REASON_NON_ESCALATION_OTHER,
] as const;

export const shouldGetFlexibleTaskInputsByTaskType = (taskType: TaskType) =>
  ![
    TaskType.TASK_TYPE_FULL_BI,
    TaskType.TASK_TYPE_PBM_BV,
    TaskType.TASK_TYPE_PBM_DISCOVERY,
    TaskType.TASK_TYPE_MM_DISCOVERY,
  ].includes(taskType);

// IncidentType UUID for automation and automatically filed autopilot incidents
export const AI_INCIDENT_TYPE_UUID =
  getNodeEnv() === 'development'
    ? '4719c237-8bf8-4cd0-965e-cbe543ecccbe'
    : '738d8a67-06a9-4815-9c97-9bf983df4290';

// keep in sync with github.com/infinitusai/absinthe/blob/develop/be/internal/pkg/task/bvinputs/visit_treatment.go#L19
export const DEFAULT_NUM_BUSINESS_DAYS_BUFFER_BEFORE_ADOT = 3;

export const DEFAULT_AVAILABLE_SEATS = 25;
export const DEFAULT_NUM_CALLS_PER_TASK = 7;
export const DEFAULT_WATCHDOG_INTERVAL = 18;

export const activeRequeueReasons = [
  RequeueReason.REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK,
  RequeueReason.REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA,
  RequeueReason.REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY,
  RequeueReason.REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA,
  RequeueReason.REASON_AGENT_PROVIDED_UNRELIABLE_DATA,
  RequeueReason.REASON_AGENT_CANT_FIND_MEMBER,
  RequeueReason.REASON_AGENT_CANT_FIND_MEMBER_PLAN,
  RequeueReason.REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT,
  RequeueReason.REASON_PROVIDED_CORRECT_NUMBER_FOR_BV,
  RequeueReason.REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED,
  RequeueReason.REASON_PIE_ESCALATION_NEEDED,
  RequeueReason.REASON_INCORRECT_INPUT_INFORMATION,
  RequeueReason.REASON_MISSING_INPUT_INFORMATION,
  RequeueReason.REASON_IVR_NAVIGATION_FAILURE,
  RequeueReason.REASON_UNEXPLAINED_OUTBOUND_HANGUP,
  RequeueReason.REASON_OPERATOR_ERROR,
  RequeueReason.REASON_SYSTEM_ISSUE,
  RequeueReason.REASON_PAYER_SYSTEM_ISSUE,
  RequeueReason.REASON_PAYER_UNREACHABLE,
  RequeueReason.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD,
  RequeueReason.REASON_AGENT_AUTOMATION_HANGUP,
  RequeueReason.REASON_PROVIDER_IS_INACTIVE,
  RequeueReason.REASON_NO_BENEFITS_UNDER_THIS_PLAN,
  RequeueReason.REASON_UNABLE_TO_REVERIFY_BENEFITS,
  RequeueReason.REASON_OTHER,
] as const;

export type ActiveRequeueReason = (typeof activeRequeueReasons)[number];

export type ActiveFailReason = Exclude<TaskFailReason, (typeof DisabledFailReasons)[number]>;

export const DisabledFailReasons = [
  TaskFailReason.REASON_UNKNOWN,
  TaskFailReason.REASON_PATIENT_DECEASED,
  TaskFailReason.REASON_PLAN_TERMINATED,
  TaskFailReason.FASTTRACK_REASON_REDIAL,
  TaskFailReason.REASON_IVR_NAVIGATION_FAILURE,
  TaskFailReason.REASON_HOLD_NAVIGATION_FAILURE,
  TaskFailReason.REASON_AGENT_AUTOMATION_HANGUP,
  TaskFailReason.REASON_NO_OPERATOR_AVAILABLE,
  TaskFailReason.REASON_OTHER,
  TaskFailReason.REASON_PAST_DUE,
  TaskFailReason.REASON_PRESCRIBER_UNREACHABLE,
  TaskFailReason.REASON_TASK_EXPIRED,
  TaskFailReason.REASON_NO_OPERATOR_AVAILABLE,
] as const;

// This is the list of failure reasons shown to reviewers when they complete review
export const activeFailReasons: ActiveFailReason[] = [
  TaskFailReason.REASON_BAD_DATA,
  TaskFailReason.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD,
  TaskFailReason.REASON_CUSTOMER_CANCELED,
  TaskFailReason.REASON_INVALID_PLAN,
  TaskFailReason.REASON_MISSING_DATA,
  TaskFailReason.REASON_PAYER_REFUSES_DA,
  TaskFailReason.REASON_PAYER_REFUSES_THIRD_PARTY,
  TaskFailReason.REASON_PAYER_UNREACHABLE,
  TaskFailReason.REASON_AGENT_CANT_FIND_MEMBER,
  TaskFailReason.REASON_AGENT_CANT_FIND_MEMBER_PLAN,
  TaskFailReason.REASON_INCORRECT_INPUT_INFORMATION,
  TaskFailReason.REASON_NPI_NEEDS_CMS_REGISTRATION,
  TaskFailReason.REASON_UNABLE_TO_REVERIFY_BENEFITS,
];
