import { useAuth, UserType, PermissionName } from '@infinitusai/auth';
import {
  useOperatorPortalFeatureEnabled,
  OperatorPortalFeature,
  useAppState,
} from '@infinitusai/shared';
import Stack from '@mui/material/Stack';
import { getRoutes } from 'layout/routes';
import { useEffect, useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';

import useConditionalSx from '@infinitus/hooks/useConditionalSx';
import { useCanShowTaskList } from 'hooks/useCanShowTaskList';

import { AdminNavLayout } from './AdminNavLayout';
import { expandedNavSx, baseNavSx } from './AppNavStyles';
import { NavProvider } from './NavContext';
import { OperatorNavLayout } from './OperatorNavLayout';

interface Props {
  isExpanded: boolean;
}

function NewAppNav({ isExpanded }: Props) {
  const adminRouteMatch = useMatch('/:orgName/admin/:splat/*');
  const libraryRouteMatch = useMatch('/:orgName/admin/library/:splat/*');
  const opsRouteMatch = useMatch('/:orgName/operator/:splat/*');
  const queuePageRouteMatch = useMatch('/:orgName/queue');
  const taskListMatch = useMatch('/:orgName/operator/:task-list/:splat/*');
  const callPageMatch = useMatch('/:orgName/operator/tasks/:splat/calls/:splat');
  const { hasUserType, hasPermission } = useAuth();

  const isAdmin =
    hasUserType([UserType.ADMIN]) && hasPermission([PermissionName.ADMIN_PORTAL_ACCESS]);
  const { orgName: currentOrgName } = useAppState();

  const isUnifiedQueuesFeatureEnabled = useOperatorPortalFeatureEnabled(
    OperatorPortalFeature.UNIFIED_QUEUE
  );

  const canShowTaskList = useCanShowTaskList();

  const showUnifiedQueue = useMemo(() => {
    if (queuePageRouteMatch) return true;
    if (!isUnifiedQueuesFeatureEnabled) return false;
    if (adminRouteMatch && isAdmin) return true;
    if (opsRouteMatch && !!currentOrgName) return true;
    return false;
  }, [
    queuePageRouteMatch,
    isUnifiedQueuesFeatureEnabled,
    adminRouteMatch,
    isAdmin,
    opsRouteMatch,
    currentOrgName,
  ]);

  const baseNavList = useMemo(() => {
    return getRoutes({
      isAdmin,
      orgName: currentOrgName,
      showUnifiedQueue,
      canShowTaskList,
    });
  }, [canShowTaskList, isAdmin, currentOrgName, showUnifiedQueue]);

  const navSx = useConditionalSx(baseNavSx, [isExpanded, expandedNavSx]);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [showAllPages, setShowAllPages] = useState(false);

  useEffect(() => {
    if (!isExpanded) {
      setSearchTerm('');
      setSearchOpen(false);
    }
  }, [isExpanded]);

  const filteredNavList = useMemo(() => {
    if (!searchTerm) return baseNavList;

    const term = searchTerm.toLowerCase();
    return baseNavList.filter((item) => item.title.toLowerCase().includes(term));
  }, [baseNavList, searchTerm]);

  const sortedNavList = useMemo(() => {
    const isSpecial = (id: string) => {
      const lowerId = id.toLowerCase();
      return lowerId === 'ready' || lowerId === 'queue';
    };

    return [...filteredNavList]
      .sort((a, b) => a.title.localeCompare(b.title))
      .sort((a, b) => Number(isSpecial(b.id)) - Number(isSpecial(a.id)));
  }, [filteredNavList]);

  let match;
  if (libraryRouteMatch) {
    match = libraryRouteMatch;
  } else if (adminRouteMatch) {
    // This will break if/when we add more roles
    match = adminRouteMatch;
  } else if (taskListMatch) {
    match = taskListMatch;
  } else if (opsRouteMatch) {
    match = opsRouteMatch;
    const orgName = match?.params?.orgName;
    if (!orgName) return null;
  } else if (isAdmin) {
    match = adminRouteMatch;
  }

  return (
    <Stack height="100%" sx={navSx}>
      <NavProvider navList={baseNavList}>
        {isAdmin ? (
          <AdminNavLayout
            callPageMatch={!!callPageMatch}
            filteredNavList={sortedNavList}
            isExpanded={isExpanded}
            searchOpen={searchOpen}
            searchTerm={searchTerm}
            setSearchOpen={setSearchOpen}
            setSearchTerm={setSearchTerm}
            setShowAllPages={setShowAllPages}
            showAllPages={showAllPages}
          />
        ) : (
          <OperatorNavLayout filteredNavList={sortedNavList} isExpanded={isExpanded} />
        )}
      </NavProvider>
    </Stack>
  );
}

export { NewAppNav };
