import { useReactiveVar } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '@infinitus/components/Button';
import { DeclarativeSnackbarMessage } from '@infinitus/components/Snackbar/DeclarativeSnackbarMessage';
// import useApi from '@infinitus/hooks/useApi';
import useGetIdsFromUrl from '@infinitus/hooks/useGetIdsFromUrl';
import useInterval from '@infinitus/hooks/useInterval';
import ThemeColorTypes from '@infinitus/types/theme-color-types';
import dayjs from '@infinitus/utils/dayjs';
import { taskStateVar } from 'apollo/cache';
import { TaskState } from 'generated/gql/graphql';
import { getFrontendVersion, getNodeEnv } from 'utils/environments';

const TEMP_SKIP_FRONTEND_VERSION_CHECK = true;
const VERSION_CHECK_INTERVAL = 30000; // 30 seconds
const AUTO_PAGE_RELOAD_TIMER = 60; // 1 minute
const RELOAD_SNACKBAR_KEY = 'RELOAD_SNACKBAR';

const CountdownSnackbar = () => {
  const [countdown, setCountdown] = useState(AUTO_PAGE_RELOAD_TIMER);
  useEffect(() => {
    if (countdown === 0) {
      window.location.reload();
    }
  }, [countdown]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box paddingX={1}>
      <Typography variant="body1">A new version is available.</Typography>
      <Typography variant="body1">
        {`Reloading in
        ${dayjs.duration({ seconds: countdown }).format('s')} seconds.`}
      </Typography>
    </Box>
  );
};

function CheckVersion() {
  // const { api } = useApi();
  const { callUuid } = useGetIdsFromUrl();
  const taskState = useReactiveVar(taskStateVar);

  const isTesting = getNodeEnv() === 'test';
  const localVersion = getFrontendVersion();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const hasActiveCall = useMemo(
    () => !!callUuid && (taskState?.state === TaskState.TASK_STATE_CALL_CONNECTED || true),
    [callUuid, taskState?.state]
  );

  useEffect(() => {
    if (hasActiveCall) {
      setShowSnackbar(false);
    }
  }, [hasActiveCall]);

  useInterval(async () => {
    // Skip version checks if in testing or on connected call
    if (isTesting) {
      console.log('Skipping version checks in testing environment');
      return;
    } else if (hasActiveCall) {
      return;
    }

    // TODO[TEC-552] Get frontend version from frontend server
    if (TEMP_SKIP_FRONTEND_VERSION_CHECK) {
      return;
    }
    console.info(localVersion);
    // try {
    //   const body = infinitusai.be.FrontendVersionRequest.create();
    //   const response = await api.latestVersion(null, body);
    //   const version = response?.data?.version;
    //   if (version !== localVersion && !showSnackbar) {
    //     setShowSnackbar(true);
    //   }
    // } catch (e: any) {
    //   console.error(`Failed to perform version check: ${e?.response?.data || e.message}`);
    // }
  }, VERSION_CHECK_INTERVAL);

  return (
    <DeclarativeSnackbarMessage
      message={<CountdownSnackbar />}
      options={{
        variant: 'info',
        action: (
          <Button
            color={ThemeColorTypes.INHERIT}
            onClick={() => window.location.reload()}
            text="Reload"
          />
        ),
      }}
      show={showSnackbar}
      snackKey={RELOAD_SNACKBAR_KEY}
    />
  );
}

export default CheckVersion;
