import { SxProps } from '@mui/material';

import MuiTheme from 'styles/MuiTheme';

import { expandTransitionDuration, sizeDiff } from '../LayoutConstants';

export const baseNavSx: SxProps<typeof MuiTheme> = {
  transform: `translate3d(${sizeDiff}px,0,0)`,
  transitionDuration: expandTransitionDuration,
  pt: 0,
  cursor: 'pointer',
};

export const expandedNavSx: SxProps<typeof MuiTheme> = {
  transform: `translate3d(0,0,0)`,
};
