import { useAuth } from '@infinitusai/auth';
import { createContext, useContext, useState, useCallback, useEffect } from 'react';

import { addFavorite, getFavorites, removeFavorite, reorderFavorites } from './favoriteStorage';
import { NavItemWithHref } from './types';

interface NavContextType {
  addToFavorites: (item: NavItemWithHref) => void;
  favorites: NavItemWithHref[];
  isFavorite: (id: string) => boolean;
  navList: NavItemWithHref[];
  removeFromFavorites: (id: string) => void;
  updateFavorites: (newFavorites: NavItemWithHref[]) => void;
}

export const NavContext = createContext<NavContextType>({
  navList: [],
  favorites: [],
  isFavorite: () => false,
  addToFavorites: () => {},
  removeFromFavorites: () => {},
  updateFavorites: () => {},
});

export const NavProvider = ({
  children,
  navList,
}: {
  children: React.ReactNode;
  navList: NavItemWithHref[];
}) => {
  const { user } = useAuth();
  const userId = user?.uid ?? '';
  const [favoriteIds, setFavoriteIds] = useState<string[]>(() => getFavorites(userId));

  const handleFavoritesChange = useCallback(() => {
    const newFavoriteIds = getFavorites(userId);
    setFavoriteIds(newFavoriteIds);
  }, [userId]);

  const addToFavorites = useCallback(
    (item: NavItemWithHref) => {
      addFavorite(userId, item);
      handleFavoritesChange();
    },
    [userId, handleFavoritesChange]
  );

  const removeFromFavorites = useCallback(
    (id: string) => {
      removeFavorite(userId, id);
      handleFavoritesChange();
    },
    [userId, handleFavoritesChange]
  );

  const updateFavorites = useCallback(
    (newFavorites: NavItemWithHref[]) => {
      reorderFavorites(userId, newFavorites);
      setFavoriteIds(newFavorites.map((item) => item.id));
    },
    [userId]
  );

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key?.includes('favorites')) {
        handleFavoritesChange();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('favorites-changed', handleFavoritesChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('favorites-changed', handleFavoritesChange);
    };
  }, [handleFavoritesChange]);

  const value = {
    navList,
    favorites: favoriteIds
      .map((id) => navList.find((item) => item.id === id))
      .filter((item) => item !== undefined),
    isFavorite: (id: string) => favoriteIds.includes(id),
    addToFavorites,
    removeFromFavorites,
    updateFavorites,
  };

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export const useNavContext = () => useContext(NavContext);
