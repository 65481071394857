import { FieldPolicy, FieldReadFunction, InMemoryCache, makeVar } from '@apollo/client';
import { isEqual } from 'lodash';

import { PrimarySuggestionFragment } from '@infinitus/fragments/conversation';
import {
  OutputFieldValueEffectType,
  SuggestionCollarType,
  Visibility,
} from 'generated/gql/graphql';
import {
  PrimarySuggestionFragment as PrimarySuggestionFragmentType,
  RulesQuery_rules,
} from 'types/gqlMapping';
import { Output, OutputFieldValueEffect, OutputFieldVisibilityEffect } from 'types/graphql-simple';
import { extractOutputValue } from 'utils/callOutputs';

import { doesEffectSatisfyNotInComparison } from './rulesHelpers';

export interface ActiveOutputEffectType {
  valueEffect?: OutputFieldValueEffect;
  visibilityEffect?: OutputFieldVisibilityEffect;
}

// These are the effects that are "active" (all conditions are met),
// keyed by output field name. Each output field can have a max of one
// active visibility effect and one value effect.
export const activeOutputFieldEffectsVar = makeVar<{
  [outputFieldName: string]: ActiveOutputEffectType;
}>({});

export const activeRulesVar = makeVar<RulesQuery_rules[]>([]);

export const rulesQueryTypePolicies: {
  [fieldName: string]: FieldPolicy<any> | FieldReadFunction<any>;
} = {
  suggestionCollar: {
    read(_, { cache, readField, variables }) {
      const suggestionId: string = variables?.suggestionId;
      const suggestion = readSuggestionFromCache(cache, suggestionId);

      // for each output field, find effect
      const effects = suggestion?.outputFields?.map((outputField) => {
        return readField<SuggestionCollarType>({
          fieldName: 'outputFieldEffect',
          variables: {
            fieldName: outputField.name,
            hideByDefault: outputField.hideByDefault,
          },
        });
      });

      switch (true) {
        case effects?.includes(SuggestionCollarType.ESCALATE):
          return SuggestionCollarType.ESCALATE;
        case effects?.includes(SuggestionCollarType.OVERRIDE):
          return SuggestionCollarType.OVERRIDE;
        case effects?.includes(SuggestionCollarType.ERROR):
          return SuggestionCollarType.ERROR;
        case effects?.includes(SuggestionCollarType.PI_ACTIVE):
          return SuggestionCollarType.PI_ACTIVE;
        case effects?.includes(SuggestionCollarType.DEFAULT):
          return SuggestionCollarType.DEFAULT;
        case effects?.includes(SuggestionCollarType.ANSWERED):
        case effects?.includes(SuggestionCollarType.PI_ANSWERED):
          return SuggestionCollarType.ANSWERED;
        default:
          return SuggestionCollarType.SKIP;
      }
    },
  },
  outputFieldEffect: {
    read(_, { readField, variables }) {
      const fieldName = variables?.fieldName;
      if (!fieldName) {
        return null;
      }

      const hideByDefault = variables?.hideByDefault || false;
      const current = readField<Output>({
        fieldName: 'readOutputValue',
        variables: { fieldName: fieldName },
      });

      const { valueEffect, visibilityEffect } = activeOutputFieldEffectsVar()[fieldName] || {};
      let isRelevant = !hideByDefault;
      if (visibilityEffect) {
        isRelevant = visibilityEffect.visibility === Visibility.SHOW;
      }
      if (!isRelevant) {
        return SuggestionCollarType.SKIP;
      }

      if (!current?.value) {
        // empty field
        return valueEffect ? SuggestionCollarType.PI_ACTIVE : SuggestionCollarType.DEFAULT;
      }

      if (!valueEffect) {
        return SuggestionCollarType.ANSWERED;
      }

      // Special case for checking specialtyPharmacyName. Want to check if the value from outputs bag in comma delimited list specified from payer intelligence
      // We will remove this hardcode checking in the next iteration of Payer Intelligence
      if (doesEffectSatisfyNotInComparison(fieldName)) {
        let expectedEffectVal = extractOutputValue(valueEffect.expectedValue)?.toString();
        let currentVal = extractOutputValue(current.value)?.toString();
        let expectedVals = expectedEffectVal?.split(',');
        if (currentVal && expectedVals?.includes(currentVal)) {
          return SuggestionCollarType.PI_ANSWERED;
        }
      }

      // check if value matches current.value
      if (isEqual(current.value, valueEffect.expectedValue)) {
        return SuggestionCollarType.PI_ANSWERED;
      }

      return valueEffect.effectType === OutputFieldValueEffectType.ESCALATE
        ? SuggestionCollarType.ESCALATE
        : valueEffect.effectType === OutputFieldValueEffectType.OVERRIDE
        ? SuggestionCollarType.OVERRIDE
        : SuggestionCollarType.ERROR;
    },
  },
};

// Helper functions

const readSuggestionFromCache = (cache: InMemoryCache, suggestionId: string) => {
  // read suggestion output fields & their default visibility from cache
  return cache.readFragment<PrimarySuggestionFragmentType>({
    id: suggestionId,
    fragment: PrimarySuggestionFragment,
    fragmentName: 'PrimarySuggestionFragment',
  });
};
