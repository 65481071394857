import { SxProps } from '@mui/material';
import { expandTransitionDuration } from 'layout/LayoutConstants';

import MuiTheme from 'styles/MuiTheme';

const childItemMargin = MuiTheme.spacing(1.5);

export const baseLabelSx: SxProps<typeof MuiTheme> = {
  color: 'currentcolor',
  lineHeight: 1,
  marginInline: 2,
  transition: MuiTheme.transitions.create('opacity', {
    duration: expandTransitionDuration,
    easing: 'linear',
  }),
  opacity: 0,
};

export const activeLabelSx: SxProps<typeof MuiTheme> = {
  fontWeight: 500,
};

export const expandedLabelSx: SxProps<typeof MuiTheme> = {
  opacity: 1,
};

export const baseLinkSx: SxProps<typeof MuiTheme> = {
  alignItems: 'center',
  backgroundColor: 'transparent',
  mt: 1,
  color: 'grey.400',
  display: 'flex',
  height: 40,
  marginBlockEnd: 0.5,
  paddingBlock: 1.5,
  paddingInline: 2,
  position: 'relative',
  transition: MuiTheme.transitions.create('background-color', {
    duration: expandTransitionDuration,
    easing: 'linear',
  }),
  '&:focus': {
    backgroundColor: 'grey.900',
    color: 'common.white',
    outline: 'none',
  },
  '&:hover': {
    backgroundColor: 'grey.900',
    color: 'common.white',
  },
};

export const activeLinkSx: SxProps<typeof MuiTheme> = {
  backgroundColor: 'grey.900',
  color: 'common.white',
};

export const childLinkSx: SxProps<typeof MuiTheme> = {
  marginLeft: childItemMargin,
  transform: `translateX(-${childItemMargin})`,
  transition: MuiTheme.transitions.create('transform', {
    duration: expandTransitionDuration,
  }),
};

export const expandedChildLinkSx: SxProps<typeof MuiTheme> = {
  transform: `translateX(0)`,
};

export const parentCategorySx: SxProps<typeof MuiTheme> = { cursor: 'default' };
