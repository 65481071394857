import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useState } from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { useOperatorPortalNewSideMenuExperiment } from 'hooks/experiments/useOperatorPortalNewSideMenuExperiment';
import MuiTheme from 'styles/MuiTheme';

import { AppHeader, NewAppHeader } from './AppHeader';
import { Drawer } from './Drawer';
import { Drawer as NewDrawer } from './NewDrawer';

function Layout() {
  const loginRoute = useMatch('login');
  const logoutRoute = useMatch('logout');

  const opNewSideMenuUXEnabled = useOperatorPortalNewSideMenuExperiment();

  const hideAppWrapper = Boolean(loginRoute ?? logoutRoute);

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <CssBaseline />
      <div
        style={{
          backgroundColor: MuiTheme.palette.background.default,
          display: 'flex',
          height: '100vh',
        }}
      >
        {!hideAppWrapper && (
          <ThemeProvider theme={MuiTheme}>
            {opNewSideMenuUXEnabled ? (
              <>
                <NewAppHeader />
                <NewDrawer onMouseLeave={() => setOpenDrawer(false)} />
              </>
            ) : (
              <>
                <AppHeader onMenuButtonClick={() => setOpenDrawer(!openDrawer)} />
                <Drawer isOpen={openDrawer} onMouseLeave={() => setOpenDrawer(false)} />
              </>
            )}
          </ThemeProvider>
        )}
        <main
          style={{
            color: MuiTheme.palette.text.primary,
            flexGrow: 1,
            marginBlockStart: !hideAppWrapper ? MuiTheme.spacing(6) : undefined,
            marginInlineStart: !hideAppWrapper ? MuiTheme.spacing(7) : undefined,
            overflowX: 'auto',
            padding: !hideAppWrapper ? MuiTheme.spacing(0, 3) : 0,
            width: `calc(100vw - ${MuiTheme.spacing(6)})`,
            zIndex: MuiTheme.zIndex.appBar - 2,
            backgroundColor: opNewSideMenuUXEnabled ? 'black' : '',
          }}
        >
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default Layout;
