import { CallSearchParams, toURLSearchParams } from '@infinitus/hooks/useCallSearchParams';
import { UUID } from '@infinitus/types';

//TODO: fix
export const getAdminLibraryConditionsPageUrl = (
  currentOrgName: string,
  orgNameForPage?: string,
  taskType?: string,
  actionNameWithoutMacros?: string
) => {
  let url = `/${currentOrgName}/admin/library/conditions`;
  if (orgNameForPage) {
    url += `/${orgNameForPage}`;
  }
  if (taskType) {
    url += `/${taskType}`;
  }
  if (actionNameWithoutMacros) {
    url += `/${actionNameWithoutMacros}`;
  }
  return url;
};

export const getAdminLibraryOutputsPageUrl = (
  orgName: string,
  taskType?: string,
  outputName?: string
) => {
  let url = `/${orgName}/admin/library/outputs`;
  if (taskType) {
    url += `/${taskType}`;
  }
  if (outputName) {
    url += `/${outputName}`;
  }
  return url;
};

export const getAdminLibraryApiOutputConfigsPageUrl = (orgName: string) => {
  return `/${orgName}/admin/library/apiOutputConfigs`;
};

export const getExperimentsPageUrl = (orgName: string, experimentName?: string) => {
  let url = `/${orgName}/admin/experiments`;
  if (experimentName) {
    url += `/${experimentName}`;
  }
  return url;
};

export const getIssuesPageUrl = (orgName: string) => `/${orgName}/admin/issues`;

export const getIssuePageUrl = (orgName: string, issueId: string) =>
  `/${orgName}/admin/issues/${issueId}`;

export const getPayersPageUrl = (orgName: string, payerUuid?: UUID) => {
  let url = `/${orgName}/admin/payers`;
  if (payerUuid) {
    url += `/${payerUuid}`;
  }
  return url;
};
export const getPayerRestrictedOrgsPageUrl = (orgName: string, payerUuid: UUID) => {
  return `/${orgName}/admin/payers/${payerUuid}/restrictedOrgs`;
};

export const getPhoneNumbersPageUrl = (orgName: string, phoneNumber?: string) => {
  let url = `/${orgName}/admin/phoneNumbers`;
  if (phoneNumber) {
    url += `/${phoneNumber}`;
  }
  return url;
};

export const getAdminOrgPageUrl = (orgName: string, orgUuid?: string, tabName?: string) => {
  let url = `/${orgName}/admin/orgs`;
  if (orgUuid) {
    url += `/${orgUuid}`;
  }
  if (tabName) {
    url += `/${tabName}`;
  }
  return url;
};

export const getAdminSkillDefinitionsPageUrl = (orgName: string) =>
  `/${orgName}/admin/skillDefinitions`;

export const getAdminUnifiedQueueManagerPageUrl = (orgName: string, queueUuid?: string) => {
  let url = `/${orgName}/admin/unifiedQueueManager`;
  if (queueUuid) {
    url += `/${queueUuid}`;
  }
  return url;
};

export const getAdminConvoBuilderPageUrl = (
  currentOrgName: string,
  orgNameForPage?: string,
  taskType?: string
) => {
  let url = `/${currentOrgName}/admin/convo`;
  if (orgNameForPage) {
    url += `/${orgNameForPage}`;
  }
  if (taskType) {
    url += `/${taskType}`;
  }
  return url;
};

export const getLoginPageUrl = () => '/login';

export const getSignOutPageUrl = () => '/logout';

export const getAccountPageUrl = (orgName: string) => `/${orgName}/account`;

export const getUnifiedReadyPageUrl = (orgName: string) => {
  return `/${orgName}/queue`;
};

export const getOrgPageUrl = (orgName: string) => {
  return `/${orgName}/operator`;
};

export const getOrgReadyPageUrl = (orgName: string) => `${getOrgPageUrl(orgName)}/ready`;

export const getTaskPageUrl = (orgName: string, taskUUID: string) =>
  `${getOrgPageUrl(orgName)}/tasks/${taskUUID}`;

export const getCallPageUrl = (
  orgName: string,
  taskUUID: string,
  callUUID: string,
  searchParams?: CallSearchParams
) => {
  const params = searchParams ? `?${toURLSearchParams(searchParams).toString()}` : '';
  return `${getTaskPageUrl(orgName, taskUUID)}/calls/${callUUID}${params}`;
};

export const getUserPageUrl = (userUid: string) => `/iam/users/${userUid}`;

export const getPendingTasksUrl = (orgName: string) =>
  `${getOrgPageUrl(orgName)}/task-list/pending`;

export const getAllTasksUrl = (orgName: string) => `${getOrgPageUrl(orgName)}/task-list/all`;

export const getCB2StandardOutputsLibraryPageUrl = (orgName: string, standardId: string) => {
  return `/${orgName}/admin/library/cb2-outputs?selectedOutputId=${standardId}`;
};

export const getCB2StandardActionUtterancesLibraryPageUrl = (
  orgName: string,
  standardId: string,
  standardNameSearch: string
) => {
  return generateStandardLibraryUrl(`/${orgName}/admin/library/cb2-actions-utterances`, [], {
    search: standardNameSearch,
    selectedActionUtteranceId: standardId,
  });
};

export const getCB2StandardModulesLibraryPageUrl = (
  orgName: string,
  standardId: string,
  standardNameSearch: string
) => {
  return generateStandardLibraryUrl(`/${orgName}/admin/library/cb2-modules`, [], {
    search: standardNameSearch,
    selectedModuleWithContentId: standardId,
  });
};

export const getCB2StandardConditionsLibraryPageUrl = (
  orgName: string,
  standardId: string,
  standardNameSearch: string
) => {
  return generateStandardLibraryUrl(`/${orgName}/admin/library/cb2-conditions`, [], {
    search: standardNameSearch,
    selectedConditionId: standardId,
  });
};
export const generateStandardLibraryUrl = (
  baseUrl: string,
  pathParams: (string | undefined)[],
  queryParams?: Record<string, string | undefined>
): string => {
  const url = `${baseUrl}/${pathParams.filter(Boolean).join('/')}`;
  const queryString = queryParams
    ? Object.entries(queryParams)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${encodeURIComponent(value!)}`)
        .join('&')
    : '';
  return queryString ? `${url}?${queryString}` : url;
};
