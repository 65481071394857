import { NavItemWithHref } from '../NewAppNav/types';

const BASE_FAVORITES_KEY = 'op_side_menu_favorites';

const getFavoritesKey = (userId: string) => `${BASE_FAVORITES_KEY}_${userId}`;

const getFavorites = (userId: string): string[] => {
  const stored = localStorage.getItem(getFavoritesKey(userId));
  return stored ? JSON.parse(stored) : [];
};

const addFavorite = (userId: string, item: NavItemWithHref) => {
  const favorites = getFavorites(userId);
  if (!favorites.includes(item.id)) {
    favorites.push(item.id);
    localStorage.setItem(getFavoritesKey(userId), JSON.stringify(favorites));
    window.dispatchEvent(new Event('favorites-changed'));
  }
};

const removeFavorite = (userId: string, id: string) => {
  const favorites = getFavorites(userId);
  const filtered = favorites.filter((favId) => favId !== id);
  localStorage.setItem(getFavoritesKey(userId), JSON.stringify(filtered));
  window.dispatchEvent(new Event('favorites-changed'));
};

const reorderFavorites = (userId: string, favorites: NavItemWithHref[]) => {
  const ids = favorites.map((fav) => fav.id);
  localStorage.setItem(getFavoritesKey(userId), JSON.stringify(ids));
  window.dispatchEvent(new Event('favorites-changed'));
};

export { addFavorite, getFavorites, removeFavorite, reorderFavorites };
