import { LiveKitRoom, RoomAudioRenderer } from '@livekit/components-react';
import { AxiosError } from 'axios';
import { AudioCaptureOptions, Room, Track } from 'livekit-client';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useApi from '@infinitus/hooks/useApi';
import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import { infinitusai } from '@infinitus/proto/pbjs';

import {
  LIVEKIT_SERVER,
  LiveKitClient,
  LiveKitClientProvider,
} from './LiveKitClientWrapperContext';
import { StartAudioModal } from './StartAudioModal';

interface RoomAccess {
  audio: boolean | AudioCaptureOptions;
  authToken: string;
  room: Room;
}

export default function LiveKitClientWrapperProvider({ children }: PropsWithChildren<{}>) {
  const { api } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [roomAccess, setRoomAccess] = useState<RoomAccess | undefined>(undefined);
  const roomAccessRef = useRef<RoomAccess | undefined>();
  useEffect(() => {
    roomAccessRef.current = roomAccess;
  }, [roomAccess]);

  const join: LiveKitClient['join'] = useCallback(
    async (args) => {
      const {
        orgUuid,
        taskUuid,
        callUuid,
        muteMicByDefault,
        autoGainControl,
        echoCancellation,
        noiseSuppression,
        microphoneDeviceId,
        speakerDeviceId,
      } = args;
      // TODO: protect against joining an already joined call
      console.log(
        `[LiveKit] call join room ${callUuid} with args ${JSON.stringify(args, null, 2)}`
      );
      try {
        const body = new infinitusai.be.GetLiveKitJwtRequest({ taskUuid, callUuid });
        const resp = await api.getLiveKitJwt(null, orgUuid, body);

        const roomAccessInfo: RoomAccess = {
          authToken: resp.data.jwt,
          room: new Room(),
          audio: muteMicByDefault
            ? false
            : {
                autoGainControl,
                echoCancellation,
                noiseSuppression,
                deviceId: microphoneDeviceId ?? '',
              },
        };

        setRoomAccess(roomAccessInfo);
        await roomAccessInfo.room.switchActiveDevice('audiooutput', speakerDeviceId ?? '');
      } catch (e: any) {
        console.error('Failed to join call', e);
        let errorDisplay = 'Failed to join call';
        if (e instanceof AxiosError && e.status === 400) {
          errorDisplay = `Failed to join call: ${e.message}`;
        }
        enqueueSnackbar(errorDisplay, { variant: 'error' });
      }
    },
    [api, enqueueSnackbar]
  );

  const leave: LiveKitClient['leave'] = useCallback(async () => {
    console.log('[LiveKit] call leave room');
    try {
      await roomAccessRef.current?.room.disconnect();
    } catch (e: any) {
      console.error(e);
    }
    const roomAccessInfo: RoomAccess = {
      authToken: '',
      room: new Room(),
      audio: false,
    };
    setRoomAccess(roomAccessInfo);
  }, []);

  const executeAction = useCallback(async (data: infinitusai.be.LivekitEvent) => {
    console.log(`[LiveKit] executeAction ${JSON.stringify(data.toJSON())}`);
    try {
      const encoder = new TextEncoder();
      const jsonString = JSON.stringify(data.toJSON());
      const encodedData = encoder.encode(jsonString);
      await roomAccessRef.current?.room.localParticipant.publishData(encodedData, {
        reliable: true,
        topic: 'INF_CALL_EVENT',
      });
    } catch (e: any) {
      console.error(e);
    }
  }, []);
  const updateAudioInputConstraints: LiveKitClient['updateAudioInputConstraints'] = useCallback(
    async (audioInputDeviceUpdates) => {
      const room = roomAccessRef.current?.room;
      if (!room) {
        return;
      }

      const { autoGainControl, echoCancellation, noiseSuppression } = audioInputDeviceUpdates;

      console.log(
        `[LiveKit] updating audio constraints ${JSON.stringify(audioInputDeviceUpdates, null, 2)}`
      );

      const audioCaptureOptions: AudioCaptureOptions = {
        autoGainControl,
        echoCancellation,
        noiseSuppression,
        deviceId: room.getActiveDevice('audioinput'),
      };
      // Note: manually restarting the tracks might not always work, I don't thing
      // these low level apis were meant to me used by consumers of the sdk, but
      // there is no way around it if we want to support this feature other then
      // leaving and rejoining the room with new options
      const tracks = Array.from(room.localParticipant.audioTrackPublications.values()).filter(
        (track) => track.source === Track.Source.Microphone
      );
      console.log(`[LiveKit] restarting ${tracks.length} tracks`);
      await Promise.all(tracks.map((t) => t.audioTrack?.restartTrack(audioCaptureOptions)));
    },
    []
  );

  const setAudioOutputDevice: LiveKitClient['setAudioOutputDevice'] = useCallback(
    async (deviceId) => {
      console.log(`[LiveKit] switching audio output device to ${deviceId}`);
      await roomAccessRef.current?.room.switchActiveDevice('audiooutput', deviceId, true);
    },
    []
  );

  const setAudioInputDevice: LiveKitClient['setAudioInputDevice'] = useCallback(
    async (deviceId) => {
      console.log(`[LiveKit] switching audio input device to ${deviceId}`);
      await roomAccessRef.current?.room.switchActiveDevice('audioinput', deviceId, true);
    },
    []
  );

  const value: LiveKitClient = useMemo(
    () => ({
      join,
      leave,
      executeAction,
      setAudioOutputDevice,
      setAudioInputDevice,
      updateAudioInputConstraints,
    }),
    [
      join,
      leave,
      executeAction,
      setAudioOutputDevice,
      setAudioInputDevice,
      updateAudioInputConstraints,
    ]
  );

  return (
    <LiveKitClientProvider.Provider value={value}>
      <LiveKitRoom
        audio={roomAccess?.audio}
        connect={!roomAccess?.room.name}
        onConnected={() => console.log('[LiveKit] onConnected')}
        onDisconnected={() => console.log('[LiveKit] onDisconnected')}
        onError={(e) => console.error('[LiveKit] onError', e)}
        room={roomAccess?.room}
        serverUrl={LIVEKIT_SERVER}
        token={roomAccess?.authToken}
      >
        <RoomAudioRenderer />
        <StartAudioModal />
        {children}
      </LiveKitRoom>
    </LiveKitClientProvider.Provider>
  );
}
