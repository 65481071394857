// Runtime env variables loaded via "env.js"
declare global {
  interface Window {
    INF_APP_ENV: string;
    INF_BACKEND_URL: string;
    INF_BACKEND_WS: string;
    INF_NODE_ENV: string;
    INF_OPERATOR_PRESENCE_WS: string;
    INF_REACT_APP_BUILD_VERSION: string;
    INF_REACT_APP_FIREBASE_API_KEY: string;
    INF_REACT_APP_FIREBASE_APP_ID: string;
    INF_REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
    INF_REACT_APP_GCP_PROJECT_ID: string;
    INF_REACT_APP_OPENREPLAY_INGEST_POINT: string;
    INF_REACT_APP_OPENREPLAY_PROJECT_KEY: string;
  }
}

export function getNodeEnv(): string {
  return window.INF_NODE_ENV;
}

export function isCypress(): boolean {
  return typeof window !== 'undefined' && (window as any).Cypress;
}

export function getBuildVersion(): string | undefined {
  return window.INF_REACT_APP_BUILD_VERSION;
}

export function getFrontendVersion(): string {
  return window.INF_REACT_APP_BUILD_VERSION;
}

export function getAppEnv(): string {
  return window.INF_APP_ENV;
}

export function getGcloudProjectName(): string {
  return window.INF_REACT_APP_GCP_PROJECT_ID;
}

export function isConnectedToDevDb(): boolean {
  return window.INF_REACT_APP_GCP_PROJECT_ID === 'infinitus-dev';
}

export function getGcpProjectId(): string {
  return window.INF_REACT_APP_GCP_PROJECT_ID;
}

export function getFirebaseApiKey(): string {
  return window.INF_REACT_APP_FIREBASE_API_KEY;
}

export function getFirebaseMessagingSenderId(): string {
  return window.INF_REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
}

export function getFirebaseAppId(): string {
  return window.INF_REACT_APP_FIREBASE_APP_ID;
}

export function getBackendServerHost(): string {
  if (window.INF_BACKEND_URL === '/api') return 'localhost';

  const url = new URL(window.INF_BACKEND_URL);
  return url.hostname;
}

export function getBackendPort(): number | undefined {
  if (window.INF_BACKEND_URL === '/api') return 3002;

  const url = new URL(window.INF_BACKEND_URL);
  return url.port ? Number(url.port) : undefined;
}

export function getBackendServerUrl(): string {
  return window.INF_BACKEND_URL;
}

export function getBackendWebsocketUrl(): string {
  return window.INF_BACKEND_WS;
}

export function getPresenceWebsocketUrl(): string {
  return window.INF_OPERATOR_PRESENCE_WS;
}

export function getOpenReplayIngestPoint(): string {
  return window.INF_REACT_APP_OPENREPLAY_INGEST_POINT;
}

export function getOpenReplayProjectKey(): string {
  return window.INF_REACT_APP_OPENREPLAY_PROJECT_KEY;
}

export function getOperatorPortalUrl(): string {
  return window.location.origin;
}
