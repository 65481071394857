import { ApolloProvider, ApolloClient } from '@apollo/client';
import { AppName } from '@infinitusai/api';
import React, { PropsWithChildren } from 'react';

import { WEBSOCKET_DEBUG_LISTENERS } from '@infinitus/apollo/debugMode';
import { buildTransportSplitLink } from '@infinitus/apollo/links';
import { OPEN_REPLAY_WS_LISTENERS } from 'utils/OpenReplayTracker';
import {
  getFrontendVersion,
  getNodeEnv,
  getBackendServerUrl,
  getBackendWebsocketUrl,
} from 'utils/environments';

import operatorPortalCache from './cache';

const backendUrl = getBackendServerUrl();
const websocketUrl = getBackendWebsocketUrl();

const apolloClient = new ApolloClient({
  cache: operatorPortalCache,
  link: buildTransportSplitLink(AppName.OPERATOR, backendUrl, websocketUrl, getFrontendVersion(), [
    WEBSOCKET_DEBUG_LISTENERS,
    OPEN_REPLAY_WS_LISTENERS,
  ]),
  connectToDevTools: getNodeEnv() === 'development',
});

export function InternalApolloProvider({ children }: PropsWithChildren<{}>) {
  if (getNodeEnv() === 'test') {
    return <React.Fragment children={children} />;
  }
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
