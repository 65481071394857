import blueLogo from './assets/blueLogo.svg';
import lockup from './assets/lockup.svg';
import logo from './assets/logo.svg';

interface Props {
  className?: string;
  style?: object;
  variant?: 'logo' | 'lockup' | 'blueLogo';
}

function Logo({ className, style, variant = 'logo' }: Props) {
  return (
    <img
      alt="Infinitus logo"
      className={className && className}
      src={variant === 'logo' ? logo : variant === 'lockup' ? lockup : blueLogo}
      style={style && style}
    />
  );
}

export default Logo;
