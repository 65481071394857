import { useEffect } from 'react';

import { VoiceContextInterface } from '@infinitus/voice';

import { LiveKitClient } from './livekit/LiveKitClientWrapperContext';

interface UseSyncLivekitAudioDeviceSettingsArgs {
  livekitClient: LiveKitClient;
  voiceOptions: VoiceContextInterface;
}

export function useSyncLivekitAudioDeviceSettings({
  livekitClient: lc,
  voiceOptions,
}: UseSyncLivekitAudioDeviceSettingsArgs) {
  const {
    speakerDeviceId,
    microphoneDeviceId,
    devicesLastUpdated,
    autoGainControl,
    echoCancellation,
    noiseSuppression,
  } = voiceOptions;

  // Whenever the selected microphone device changes, we need to update the livekit client
  useEffect(() => {
    if (microphoneDeviceId !== undefined) {
      void lc.setAudioInputDevice(microphoneDeviceId).catch((e) => {
        console.error(`failed to sync microphone ${e}`);
      });
    }
  }, [lc, devicesLastUpdated, microphoneDeviceId]);

  // Whenever the selected audio constraints change, we need to update the livekit client
  useEffect(() => {
    // Note: This seems to be pretty flaky because its not well supported to change after joining a call
    void lc
      .updateAudioInputConstraints({ autoGainControl, echoCancellation, noiseSuppression })
      .catch((e) => {
        console.error(`failed to sync audio constraints ${e}`);
      });
  }, [lc, autoGainControl, echoCancellation, noiseSuppression]);

  // Whenever the selected speaker device changes, we need to update the livekit client
  useEffect(() => {
    if (speakerDeviceId) {
      void lc.setAudioOutputDevice(speakerDeviceId).catch((e) => {
        console.error(`failed to sync speaker ${e}`);
      });
    }
  }, [lc, speakerDeviceId, devicesLastUpdated]);
}
